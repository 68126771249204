.bux-table {
	width: 100%;
	display: table;
    overflow-x: auto;

	caption {
		font-size: $ts-22;
		font-weight: 700;
		line-height: calc(#{$ts-22} * 1.125);
		margin-bottom: $sp-16;
		text-align: left;
		@include breakpoint(md) {
			font-size: $ts-md;
			line-height: calc(#{$ts-md} * 1.125);
		}
		p {
			font-family: inherit;
		}
	}
	th {
		font-size: $ts-base;
		padding: $sp-8 $sp-16;
		border-bottom: 2px solid $gray-dark-40;
		background-color: $white;
		text-align: left;
		font-weight: 700;

	}
	tbody th {
			font-weight: 700;
			border-bottom: none;
			border-right: 2px solid $gray-dark-40;
		}
	tr {
		border-bottom: 2px solid $gray-light-80;
	}
	td {
		font-size: $ts-base;
		padding: $sp-16;
		font-weight: 400;
	}
}

// Striped variant.
.bux-table--striped {
	tr:nth-child(even) {
		background-color: $gray-light-90;
	}
}

// Compact variant.
.bux-table--compact {
	th,
	td {
		font-size: $ts-sm;
		padding: $sp-8;
	}
}

.bux-table--sortable {
	.sort-by {
		cursor: pointer;

    button {
      color: $gray-dark-80;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      &:after {
        content: "\f00f";
        font-family: $icon;
        color: $gray-dark-80;
        margin-left: $sp-4;
      }
    }
	}
	.th-sort-asc,
	.th-sort-desc {
		background-color: $gray-light-90;
		margin-left: $sp-4;
	}
	.th-sort-asc {
    button::after	{
		  content: "\f011";
		  font-family: $icon;
    }
	}
	.th-sort-desc {
    button::after	{
		  content: "\f010";
		  font-family: $icon;
    }
	}
  &.bux-table--announcement-region {
    @include visually-hidden();
  }
}
