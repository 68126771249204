// Variables
$sp-nav: 44px;

// BUX Card Carousel
.bux-card-carousel {
  position: relative;
  overflow: hidden;
  margin: $sp-32 0;

  &__container {
    display: flex;
    overflow-x: scroll;
    scroll-padding: 0 $sp-64;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;

    // BUX Card
    .bux-card {
      scroll-snap-align: start;
      flex: 0 0 calc(100% - 128px);
      margin-right: $sp-32;

      &:first-child {
        margin-left: $sp-64;
      }

      &:last-child {
        margin-right: $sp-64;
      }

      @media (min-width: $bp-md) {
        flex: 0 0 calc(33.333% - 64px);
      }

      @media (min-width: $bp-lg) {
        flex: 0 0 calc(25% - 56px);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__nav {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    height: 100%;
    width: $sp-nav;
    font-family: bux-icons;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--left-column {
      left: 0;
    }

    &--right-column {
      right: 0;
    }

    &--left,
    &--right {
      height: $sp-nav;
      width: $sp-nav;
      background: $white;
      color: $scarlet;
      border: solid 2px $scarlet;

      &:hover {
        background: $gray-dark-60;
        color: $white;
        border-color: $gray-dark-60;
      }

      &:focus {
        outline: 2px solid $focus;
        outline-offset: 2px;
      }
    }

    &--left {
      &::before {
        content: "\f004";
        margin: auto;
      }
    }

    &--right {
      &::before {
        content: "\f005";
        margin: auto;
      }
    }
  }
}
