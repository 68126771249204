// First level.
ol.bux-list-ol {
	list-style: none;
	counter-reset: bux-ol;
	margin-left: 0;
	margin-top: 0;
	padding-left: calc(#{$sp-64} - #{$sp-16});
	font-family: $serif;
	font-size: $ts-base;
	line-height: 1.5;
	li {
		counter-increment: bux-ol;
		margin-bottom: $sp-8;
        margin-left: $sp-16;
		&::before {
			content: counter(bux-ol) ".";
			display: inline-block;
			width: $sp-24;
            margin-left: calc($sp-24 * -1);
		}
	}
}

// Second level.
ol.bux-list-ol ol {
	list-style: none;
	counter-reset: bux-ol;
	padding-left: $sp-32;
	margin-top: $sp-8;
	li {
		counter-increment: bux-ol;
		margin-bottom: $sp-8;
		&::before {
			content: counter(bux-ol, lower-alpha) ".";
			padding-right: $sp-16;
		}
	}
}

// Third level.
ol.bux-list-ol ol ol {
	list-style: none;
	counter-reset: bux-ol;
	padding-left: $sp-32;
	margin-top: $sp-8;
	li {
		counter-increment: bux-ol;
		margin-bottom: $sp-8;
		&::before {
			content: counter(bux-ol, lower-roman) ".";
			padding-right: $sp-16;
		}
	}
}
