@use 'sass:math';

$gutter: $sp-16 !default;
$container-gutter: $sp-64 !default;

// Global max-width for containers.
$max-width: $bp-xxl !default;

// Grid columns map.
$grid-columns: (
	"1": 8.333,
	"2": 16.6667,
	"3": 25,
	"4": 33.3333,
	"5": 41.6667,
	"6": 50,
	"7": 58.3333,
	"8": 66.6667,
	"9": 75,
	"10": 83.3333,
	"11": 91.6667,
	"12": 100,
);

// Container class.
.bux-container {
	width: 100%;
	max-width: $max-width;
	padding-right: ($container-gutter * 0.5);
	padding-left: ($container-gutter * 0.5);
	margin-right: auto !important;
	margin-left: auto !important;
	@include breakpoint(md) {
		padding-right: $container-gutter;
		padding-left: $container-gutter;
	}
	@include breakpoint(lg) {
		padding-right: ($container-gutter * 2);
		padding-left: ($container-gutter * 2);
	}
}

// If container and grid are on the same element,
// reduce padding by value of gutter.
.bux-container.bux-grid {
	padding-left: (($container-gutter * 0.5) - $gutter);
	padding-right: (($container-gutter * 0.5) - $gutter);
	@include breakpoint(md) {
		padding-left: ($container-gutter - $gutter);
		padding-right: ($container-gutter - $gutter);
	}
	@include breakpoint(lg) {
		padding-left: (($container-gutter * 2) - $gutter);
		padding-right: (($container-gutter * 2) - $gutter);
	}
}

// Grid basics.
.bux-grid {
	display: flex;
	flex-wrap: wrap;
	clear: both;
	margin-right: math.div($gutter, -1);
	margin-left: math.div($gutter, -1);
}

.bux-grid:after {
	display: block;
	height: 0;
	clear: both;
	content: "";
	visibility: hidden;
}

// No-gutters version of grid.
.bux-grid--no-gutters {
	margin-right: 0;
	margin-left: 0;
}

// Basic cell properties.
.bux-grid__cell {
	flex-basis: 0;
	flex-grow: 1;
	align-self: stretch;
	width: 100%;
	min-height: 1px;
	float: left;
	margin-right: $gutter;
	margin-left: $gutter;
}

// Build numbered grid classes.
@each $columns, $width in $grid-columns {
	.bux-grid > .bux-grid__cell--#{$columns} {
		$width_value: calc(#{$width} * 1% - #{$gutter} * 2);
		width: $width_value;
		flex-basis: $width_value;
		flex-grow: 0;
	}
	.bux-grid--no-gutters > .bux-grid__cell--#{$columns} {
		width: $width * 1%;
		flex-basis: $width * 1%;
		flex-grow: 0;
		margin-right: 0;
		margin-left: 0;
	}
}

// Build numbered grid classes and auto-width class per breakpoint.
@each $bp-name, $bp-size in $breakpoints {
	@if $bp-name == "sm" {
		@each $columns, $width in $grid-columns {
			.bux-grid > .bux-grid__cell--#{$columns}\@#{$bp-name} {
				$width_value: calc(#{$width} * 1% - #{$gutter} * 2);
				width: $width_value;
				flex-basis: $width_value;
				flex-grow: 0;
			}
			.bux-grid--no-gutters > .bux-grid__cell--#{$columns}\@#{$bp-name} {
				width: $width * 1%;
				flex-basis: $width * 1%;
				flex-grow: 0;
				margin-right: 0;
				margin-left: 0;
			}
		}
		.bux-grid > .bux-grid__cell--auto\@#{$bp-name} {
			flex-basis: 0;
			flex-grow: 1;
			width: 100%;
		}
	} @else {
		@media only screen and (min-width: $bp-size) {
			@each $columns, $width in $grid-columns {
				.bux-grid > .bux-grid__cell--#{$columns}\@#{$bp-name} {
					$width_value: calc(#{$width} * 1% - #{$gutter} * 2);
					width: $width_value;
					flex-basis: $width_value;
					flex-grow: 0;
				}
				.bux-grid--no-gutters > .bux-grid__cell--#{$columns}\@#{$bp-name} {
					width: $width * 1%;
					flex-basis: $width * 1%;
					flex-grow: 0;
					margin-right: 0;
					margin-left: 0;
				}
			}
			.bux-grid > .bux-grid__cell--auto\@#{$bp-name} {
				flex-basis: 0;
				flex-grow: 1;
				width: 100%;
			}
		}
	}
}
