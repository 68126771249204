@mixin alert($bg-color, $icon-color, $text-color: $black, $icon-code: "\f106") {
  position: relative;
  background-color: $bg-color;
  color: $text-color;
  display: flex;
  padding: $sp-8 $sp-16;

  .bux-alert__icon {
    line-height: 1;
    margin-right: $sp-12;

    &:after {
      content: $icon-code;
      font-family: $icon;
      font-size: $ts-md;
      color: $icon-color;
    }
  }

  .bux-alert__message-title {
    color: $text-color;
    font-size: $ts-18;
    font-family: $sans;
    font-weight: 700;
    margin-bottom: $sp-4;
    line-height: 1.5rem;
  }

  .bux-alert__message-text {
    font-family: $sans;
    font-size: $ts-base;
    line-height: 1.375;
    font-weight: 400;
  }

  .bux-alert__dismiss {
    @include button-reset();
    position: absolute;
    height: 44px;
    width: 44px;
    top: 0;
    right: 0;

    &:after {
      content: "\f105";
      font-family: $icon;
      font-size: $ts-base;
      color: $text-color;
    }

    &:focus {
      outline: 2px solid $focus;
    }
  }
}

.bux-alert {
  &.bux-alert--info {
    @include alert($info-light, $info-dark, $icon-code: "\f104");
    a {
      @include link-base("light");
    }
  }
  &.bux-alert--success {
    @include alert($success-light, $success-dark, $icon-code: "\f102");
    a {
      @include link-base("light");
    }
  }
  &.bux-alert--warning {
    @include alert($warning-light, $warning-dark, $icon-code: "\f108");
    a {
      @include link-base("light");
    }
  }
  &.bux-alert--error {
    @include alert($error, $white, $white, $icon-code: "\f106");
    a {
      @include link-base("scarlet");
    }
  }
}
