// Breakpoint values.
$breakpoints: (
  sm: 0,
  md: 640px,
  lg: 960px,
  xl: 1216px,
  xxl: 1440px,
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// Breakpoint variables.
$bp-sm: map-get($breakpoints, sm) !default;
$bp-md: map-get($breakpoints, md) !default;
$bp-lg: map-get($breakpoints, lg) !default;
$bp-xl: map-get($breakpoints, xl) !default;
$bp-xxl: map-get($breakpoints, xxl) !default;

// Hide on mobile

.mobile-hidden {
    @media (max-width: map-get($breakpoints, lg)) {
        display: none;
    }
}

// Show on mobile

.mobile-visible {
    visibility: visible;
    @include breakpoint(lg) {
        visibility: collapse;
    }
}
