.bux-skip-navigation {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: auto;
	right: auto;
	top: -9999px;
	width: 1px;
	height: 1px;
	overflow: hidden;
	@include link-base();
	
	&:focus {
		line-height: auto;
		height: auto;
		width: auto;
		padding: 0 $sp-12;
		clip: auto;
		z-index: 2400;
		text-align: center;
		border-top: 0;
		margin: $sp-4;
		top: 0;
		box-shadow: 0 0 2px 0 rgba(0,0,0,.7);
	}
	
}