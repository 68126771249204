.bux-factoid {
	@include breakpoint(lg) {
		display: flex;
	}
}
.bux-factoid__item {
	padding: $sp-12 $sp-16;
	display: block;

	&:last-child {
		border-right: none;
	}

	@include breakpoint(lg) {
		border-right: 2px solid $gray-light-60;
		padding: 0 $sp-16;
		flex: 1 1 0;
	}
}

.bux-factoid__stat {
	display: block;
	color: $scarlet;
	font-family: $sans;
	font-size: $ts-xxl;
	font-weight: 900;
	line-height: 4.125rem;
	text-align: center;

	@include breakpoint(lg) {
		font-size: rem-calc(70);
		line-height: 6rem;
	}

	sup {
		font-size: 2.5rem;
		line-height: 1;
	}
}
.bux-factoid__text {
	display: block;
	color: $gray-dark-80;
	font-family: $sans;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 2rem;
	text-align: center;

	@include breakpoint(lg) {
		font-size: $ts-22;
		line-height: rem-calc(28);
	}
}
