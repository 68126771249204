@mixin footer-variant(
	$bg-color: $gray-light-90,
	$txt-color: $gray-dark-80,
	$border-color: $gray-light-60,
	$link-color: $gray-dark-80,
	$link-hov-color: $scarlet,
	$link-hov-bg: $white,
	$link-outline: $focus
) {
	min-height: 200px;
	padding: $sp-24 0;
	background-color: $bg-color;
	color: $txt-color;

	.bux-link {
		color: $link-color;
		border-bottom: 1px solid $link-color;

		&:hover {
			background-color: $link-hov-bg;
			color: $link-hov-color;
			border-bottom-color: $link-hov-color;
		}
		&:focus {
			outline: 2px solid $link-outline;
			background-color: $link-hov-bg;
			color: $link-hov-color;
		}
	}

	hr {
		border: none;
		border-top: 2px solid $border-color;
		margin-bottom: $sp-16;
	}

	.bux-footer__col--left {
		margin-bottom: $sp-48;
		@include breakpoint(md) {
			margin-bottom: 0;
			padding-right: $sp-32;
		}
	}

	.bux-footer__col--right {
		@include breakpoint(md) {
			padding-left: $sp-32;
		}
	}

	.bux-footer__logo {
		margin-bottom: $sp-16;

		a {
			display: inline-block;
			width: 270px;

			@include breakpoint(md) {
				width: 100%;
			}

			@include breakpoint(lg) {
				width: 290px;
			}
			&:focus {
				outline: 2px solid $link-outline;
			}
		}
	}

	.bux-footer__contact {
		@include breakpoint(md) {
			max-width: 300px;
		}

		p {
			font-family: $sans;
			font-size: $ts-sm;
			margin-bottom: 0;
			line-height: 1.375;
		}
	}

	.bux-footer__contact--address {
		padding-bottom: $sp-8;
		margin-bottom: $sp-8;
	}

	.bux_footer__site-name {
		font-weight: 700;
	}

	p.bux_footer__email,
    p.bux_footer__phone,
    p.bux_footer__fax {
		margin: $sp-8 0;
	}

	.bux-footer__social {
		@include breakpoint(md) {
			text-align: right;
		}
	}

	.bux-footer__fine-print {
		margin-top: $sp-16;

		div,
		p {
			font-family: $sans;
			font-size: $ts-xs;
			margin-bottom: 0;
		}

		text-align: left;
		@include breakpoint(md) {
			text-align: right;
		}
	}

	.bux-footer__ada {
		@include breakpoint(md) {
			max-width: 400px;
			float: right;
		}
	}

	.bux-footer__links {
		clear: both;
		padding-top: $sp-16;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
	}

	.bux-footer__copyright {
		clear: both;
		margin-top: $sp-16;
	}
}

// Apply mixins per variant.
.bux-footer,
.bux-footer--light {
	@include footer-variant();
}
.bux-footer--dark {
	@include footer-variant($gray-dark-80, $white, $gray-dark-20, $white, $white, $gray-dark-40);
}
.bux-footer--white {
	@include footer-variant($white, $gray-dark-80, $gray-light-80, $gray-dark-80, $scarlet, $gray-light-80);
	border-top: 2px solid $gray-light-60;
}

//Cookie link fix 

#ot-sdk-btn.ot-sdk-show-settings, 
#ot-sdk-btn.optanon-show-settings {

    color: $gray-dark-80!important;
    border: none!important;
    border-bottom: 1px solid $gray-dark-80!important;
    padding: 0!important;
    font-size: $ts-xs!important;
    line-height: 1.5!important;

    &:hover {
    	cursor: pointer;
        color: $scarlet!important;
        background-color: $white!important;
        border-color: $scarlet!important;
    }
}
