.bux-quote {
  border-left: 4px solid $scarlet;
  padding: 0 0 0 $sp-32;

  p {
    font-family: $serif;
    font-size: $ts-md;
    font-weight: 900;
    line-height: rem-calc(32);
    margin: 0 0 $sp-16;
    padding: 0;

    @include breakpoint(md) {
      font-size: $ts-md;
      line-height: 2.25rem;
    }
  }

  &__image {
    align-self: flex-start;
    margin: 0 0 $sp-24 0;
    max-width: 100px;
    position: relative;

    @include breakpoint(md) {
      margin: 0 $sp-24 0 0;
      position: absolute;
    }
  }

  &__author {
    font-family: $sans;
    font-size: $ts-18;
    font-weight: 700;
    line-height: 1.5rem;
  }

  &__source {
    font-family: $sans;
    font-size: $ts-18;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
}
