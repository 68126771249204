// Primary Colors
$scarlet: #ba0c2f;
$gray: #a7b1b7;
$white: #fff;

// Scarlet Shades
$scarlet-dark-40: #70071c;
$scarlet-dark-60: #4a0513;

// Gray Tints
$gray-light-20: #bfc6cb;
$gray-light-40: #cfd4d8;
$gray-light-60: #dfe3e5;
$gray-light-80: #eff1f2;
$gray-light-90: #f6f7f8;

// Gray Shades
$gray-dark-20: #868e92;
$gray-dark-40: #646a6e;
$gray-dark-60: #3f4443;
$gray-dark-80: #212325;

// Neutrals
$clear: rgba(0, 0, 0, 0);
$black: #000;

// Accent
$blue: #41b6e6;
$orange: #ff6a39;
$green: #8edd65;
$brown: #946037;
$pink: #fb637e;
$violet: #830065;
$aqua: #6bbbab;
$teal: #26686d;
$gold: #ffb600;

// Utility
$info-light: #c6e9f8;
$info-dark: #2E7FA1;
$success-light: #dcf5d0;
$success-dark: #55853D;
$warning-light: #fff0cc;
$warning-dark: #E65F33;
$error: #ba0c2f;
$focus: #3492b8;
$focus-light: #74BAD8;
$visited: #660099;
$visited-light: #e7cdf4;
