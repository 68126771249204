.bux-text-field {
  @include form-element;

  &__input {
    @include form-input;
    height: 40px;

    &:focus {
      outline: 2px solid $focus;
    }

    &--disabled {
      @include form-input-disabled;
    }

    &--error {
      @include form-input-error;
    }

    &--password {
      display: inline-block;
    }
  }

  &__error-message {
    @include form-error-message;
  }

  &__password-icon {
    @include button-reset;
    height: 30px;
    width: 36px;
    font-size: 18px;
    position: absolute;
    right: 2px;
    margin-top: 18px;

    &:focus {
      outline: 2px solid $focus;
    }

    &--view:after {
      content: "\f014";
      font-family: $icon;
    }

    &--hide:after {
      content: "\f100";
      font-family: $icon;
    }
  }
}
