a.bux-text-link {
	font-family: $sans;
	font-size: $ts-base;
	color: $gray-dark-80;
	font-weight: 400;
	text-decoration: none;
	margin-right: 25px;
    position: relative;

	&:after {
		content: "\f005";
		font-size: $ts-sm;
		font-family: $icon;
		color: $scarlet;
		font-weight: 600;
		padding-left: $sp-8;
		transition-duration: 0.05s;
		position: absolute;
		top: rem-calc(1.5);
	}

	&:hover {
		color: $scarlet;
		text-decoration: none;

		&:after {
			padding-left: $sp-16;
			transition-duration: 0.05s;
		}
	}

	&:focus {
		outline: 2px solid $focus;
	}
}
