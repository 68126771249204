@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;600");
@import url("https://assets.bux.osu.edu/bux-icons/bux-icons.css");
@import url("https://s3.amazonaws.com/assets.bux.osu.edu/bux-webfonts2/bux-webfonts2.css");
/* A modern CSS reset.
https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.bux-mar-all-sp-4 {
  margin: 4px !important;
}

.bux-pad-all-sp-4 {
  padding: 4px !important;
}

.bux-mar-tb-sp-4 {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.bux-mar-lr-sp-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.bux-pad-tb-sp-4 {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.bux-pad-lr-sp-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.bux-mar-all-sp-8 {
  margin: 8px !important;
}

.bux-pad-all-sp-8 {
  padding: 8px !important;
}

.bux-mar-tb-sp-8 {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.bux-mar-lr-sp-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.bux-pad-tb-sp-8 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.bux-pad-lr-sp-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.bux-mar-all-sp-12 {
  margin: 12px !important;
}

.bux-pad-all-sp-12 {
  padding: 12px !important;
}

.bux-mar-tb-sp-12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.bux-mar-lr-sp-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.bux-pad-tb-sp-12 {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.bux-pad-lr-sp-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.bux-mar-all-sp-16 {
  margin: 16px !important;
}

.bux-pad-all-sp-16 {
  padding: 16px !important;
}

.bux-mar-tb-sp-16 {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.bux-mar-lr-sp-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.bux-pad-tb-sp-16 {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.bux-pad-lr-sp-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.bux-mar-all-sp-24 {
  margin: 24px !important;
}

.bux-pad-all-sp-24 {
  padding: 24px !important;
}

.bux-mar-tb-sp-24 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.bux-mar-lr-sp-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.bux-pad-tb-sp-24 {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.bux-pad-lr-sp-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.bux-mar-all-sp-32 {
  margin: 32px !important;
}

.bux-pad-all-sp-32 {
  padding: 32px !important;
}

.bux-mar-tb-sp-32 {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.bux-mar-lr-sp-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.bux-pad-tb-sp-32 {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}

.bux-pad-lr-sp-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.bux-mar-all-sp-48 {
  margin: 48px !important;
}

.bux-pad-all-sp-48 {
  padding: 48px !important;
}

.bux-mar-tb-sp-48 {
  margin-bottom: 48px !important;
  margin-top: 48px !important;
}

.bux-mar-lr-sp-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.bux-pad-tb-sp-48 {
  padding-bottom: 48px !important;
  padding-top: 48px !important;
}

.bux-pad-lr-sp-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.bux-mar-all-sp-64 {
  margin: 64px !important;
}

.bux-pad-all-sp-64 {
  padding: 64px !important;
}

.bux-mar-tb-sp-64 {
  margin-bottom: 64px !important;
  margin-top: 64px !important;
}

.bux-mar-lr-sp-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.bux-pad-tb-sp-64 {
  padding-bottom: 64px !important;
  padding-top: 64px !important;
}

.bux-pad-lr-sp-64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.bux-mar-all-sp-96 {
  margin: 96px !important;
}

.bux-pad-all-sp-96 {
  padding: 96px !important;
}

.bux-mar-tb-sp-96 {
  margin-bottom: 96px !important;
  margin-top: 96px !important;
}

.bux-mar-lr-sp-96 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.bux-pad-tb-sp-96 {
  padding-bottom: 96px !important;
  padding-top: 96px !important;
}

.bux-pad-lr-sp-96 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.bux-mar-top-sp-4 {
  margin-top: 4px !important;
}

.bux-pad-top-sp-4 {
  padding-top: 4px !important;
}

.bux-mar-top-sp-8 {
  margin-top: 8px !important;
}

.bux-pad-top-sp-8 {
  padding-top: 8px !important;
}

.bux-mar-top-sp-12 {
  margin-top: 12px !important;
}

.bux-pad-top-sp-12 {
  padding-top: 12px !important;
}

.bux-mar-top-sp-16 {
  margin-top: 16px !important;
}

.bux-pad-top-sp-16 {
  padding-top: 16px !important;
}

.bux-mar-top-sp-24 {
  margin-top: 24px !important;
}

.bux-pad-top-sp-24 {
  padding-top: 24px !important;
}

.bux-mar-top-sp-32 {
  margin-top: 32px !important;
}

.bux-pad-top-sp-32 {
  padding-top: 32px !important;
}

.bux-mar-top-sp-48 {
  margin-top: 48px !important;
}

.bux-pad-top-sp-48 {
  padding-top: 48px !important;
}

.bux-mar-top-sp-64 {
  margin-top: 64px !important;
}

.bux-pad-top-sp-64 {
  padding-top: 64px !important;
}

.bux-mar-top-sp-96 {
  margin-top: 96px !important;
}

.bux-pad-top-sp-96 {
  padding-top: 96px !important;
}

.bux-mar-right-sp-4 {
  margin-right: 4px !important;
}

.bux-pad-right-sp-4 {
  padding-right: 4px !important;
}

.bux-mar-right-sp-8 {
  margin-right: 8px !important;
}

.bux-pad-right-sp-8 {
  padding-right: 8px !important;
}

.bux-mar-right-sp-12 {
  margin-right: 12px !important;
}

.bux-pad-right-sp-12 {
  padding-right: 12px !important;
}

.bux-mar-right-sp-16 {
  margin-right: 16px !important;
}

.bux-pad-right-sp-16 {
  padding-right: 16px !important;
}

.bux-mar-right-sp-24 {
  margin-right: 24px !important;
}

.bux-pad-right-sp-24 {
  padding-right: 24px !important;
}

.bux-mar-right-sp-32 {
  margin-right: 32px !important;
}

.bux-pad-right-sp-32 {
  padding-right: 32px !important;
}

.bux-mar-right-sp-48 {
  margin-right: 48px !important;
}

.bux-pad-right-sp-48 {
  padding-right: 48px !important;
}

.bux-mar-right-sp-64 {
  margin-right: 64px !important;
}

.bux-pad-right-sp-64 {
  padding-right: 64px !important;
}

.bux-mar-right-sp-96 {
  margin-right: 96px !important;
}

.bux-pad-right-sp-96 {
  padding-right: 96px !important;
}

.bux-mar-bottom-sp-4 {
  margin-bottom: 4px !important;
}

.bux-pad-bottom-sp-4 {
  padding-bottom: 4px !important;
}

.bux-mar-bottom-sp-8 {
  margin-bottom: 8px !important;
}

.bux-pad-bottom-sp-8 {
  padding-bottom: 8px !important;
}

.bux-mar-bottom-sp-12 {
  margin-bottom: 12px !important;
}

.bux-pad-bottom-sp-12 {
  padding-bottom: 12px !important;
}

.bux-mar-bottom-sp-16 {
  margin-bottom: 16px !important;
}

.bux-pad-bottom-sp-16 {
  padding-bottom: 16px !important;
}

.bux-mar-bottom-sp-24 {
  margin-bottom: 24px !important;
}

.bux-pad-bottom-sp-24 {
  padding-bottom: 24px !important;
}

.bux-mar-bottom-sp-32 {
  margin-bottom: 32px !important;
}

.bux-pad-bottom-sp-32 {
  padding-bottom: 32px !important;
}

.bux-mar-bottom-sp-48 {
  margin-bottom: 48px !important;
}

.bux-pad-bottom-sp-48 {
  padding-bottom: 48px !important;
}

.bux-mar-bottom-sp-64 {
  margin-bottom: 64px !important;
}

.bux-pad-bottom-sp-64 {
  padding-bottom: 64px !important;
}

.bux-mar-bottom-sp-96 {
  margin-bottom: 96px !important;
}

.bux-pad-bottom-sp-96 {
  padding-bottom: 96px !important;
}

.bux-mar-left-sp-4 {
  margin-left: 4px !important;
}

.bux-pad-left-sp-4 {
  padding-left: 4px !important;
}

.bux-mar-left-sp-8 {
  margin-left: 8px !important;
}

.bux-pad-left-sp-8 {
  padding-left: 8px !important;
}

.bux-mar-left-sp-12 {
  margin-left: 12px !important;
}

.bux-pad-left-sp-12 {
  padding-left: 12px !important;
}

.bux-mar-left-sp-16 {
  margin-left: 16px !important;
}

.bux-pad-left-sp-16 {
  padding-left: 16px !important;
}

.bux-mar-left-sp-24 {
  margin-left: 24px !important;
}

.bux-pad-left-sp-24 {
  padding-left: 24px !important;
}

.bux-mar-left-sp-32 {
  margin-left: 32px !important;
}

.bux-pad-left-sp-32 {
  padding-left: 32px !important;
}

.bux-mar-left-sp-48 {
  margin-left: 48px !important;
}

.bux-pad-left-sp-48 {
  padding-left: 48px !important;
}

.bux-mar-left-sp-64 {
  margin-left: 64px !important;
}

.bux-pad-left-sp-64 {
  padding-left: 64px !important;
}

.bux-mar-left-sp-96 {
  margin-left: 96px !important;
}

.bux-pad-left-sp-96 {
  padding-left: 96px !important;
}

.bux-mar-all-none {
  margin: 0 !important;
}

.bux-pad-all-none {
  padding: 0 !important;
}

.bux-mar-top-none {
  margin-top: 0 !important;
}

.bux-pad-top-none {
  padding-top: 0 !important;
}

.bux-mar-right-none {
  margin-right: 0 !important;
}

.bux-pad-right-none {
  padding-right: 0 !important;
}

.bux-mar-bottom-none {
  margin-bottom: 0 !important;
}

.bux-pad-bottom-none {
  padding-bottom: 0 !important;
}

.bux-mar-left-none {
  margin-left: 0 !important;
}

.bux-pad-left-none {
  padding-left: 0 !important;
}

@media (max-width: 960px) {
  .mobile-hidden {
    display: none;
  }
}

.mobile-visible {
  visibility: visible;
}
@media (min-width: 960px) {
  .mobile-visible {
    visibility: collapse;
  }
}

.bux-container {
  width: 100%;
  max-width: 1440px;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto !important;
  margin-left: auto !important;
}
@media (min-width: 640px) {
  .bux-container {
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media (min-width: 960px) {
  .bux-container {
    padding-right: 128px;
    padding-left: 128px;
  }
}

.bux-container.bux-grid {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 640px) {
  .bux-container.bux-grid {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 960px) {
  .bux-container.bux-grid {
    padding-left: 112px;
    padding-right: 112px;
  }
}

.bux-grid {
  display: flex;
  flex-wrap: wrap;
  clear: both;
  margin-right: -16px;
  margin-left: -16px;
}

.bux-grid:after {
  display: block;
  height: 0;
  clear: both;
  content: "";
  visibility: hidden;
}

.bux-grid--no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.bux-grid__cell {
  flex-basis: 0;
  flex-grow: 1;
  align-self: stretch;
  width: 100%;
  min-height: 1px;
  float: left;
  margin-right: 16px;
  margin-left: 16px;
}

.bux-grid > .bux-grid__cell--1 {
  width: calc(8.333 * 1% - 16px * 2);
  flex-basis: calc(8.333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--1 {
  width: 8.333%;
  flex-basis: 8.333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--2 {
  width: calc(16.6667 * 1% - 16px * 2);
  flex-basis: calc(16.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--2 {
  width: 16.6667%;
  flex-basis: 16.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--3 {
  width: calc(25 * 1% - 16px * 2);
  flex-basis: calc(25 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--3 {
  width: 25%;
  flex-basis: 25%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--4 {
  width: calc(33.3333 * 1% - 16px * 2);
  flex-basis: calc(33.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--4 {
  width: 33.3333%;
  flex-basis: 33.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--5 {
  width: calc(41.6667 * 1% - 16px * 2);
  flex-basis: calc(41.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--5 {
  width: 41.6667%;
  flex-basis: 41.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--6 {
  width: calc(50 * 1% - 16px * 2);
  flex-basis: calc(50 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--6 {
  width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--7 {
  width: calc(58.3333 * 1% - 16px * 2);
  flex-basis: calc(58.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--7 {
  width: 58.3333%;
  flex-basis: 58.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--8 {
  width: calc(66.6667 * 1% - 16px * 2);
  flex-basis: calc(66.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--8 {
  width: 66.6667%;
  flex-basis: 66.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--9 {
  width: calc(75 * 1% - 16px * 2);
  flex-basis: calc(75 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--9 {
  width: 75%;
  flex-basis: 75%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--10 {
  width: calc(83.3333 * 1% - 16px * 2);
  flex-basis: calc(83.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--10 {
  width: 83.3333%;
  flex-basis: 83.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--11 {
  width: calc(91.6667 * 1% - 16px * 2);
  flex-basis: calc(91.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--11 {
  width: 91.6667%;
  flex-basis: 91.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--12 {
  width: calc(100 * 1% - 16px * 2);
  flex-basis: calc(100 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--12 {
  width: 100%;
  flex-basis: 100%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--1\@sm {
  width: calc(8.333 * 1% - 16px * 2);
  flex-basis: calc(8.333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--1\@sm {
  width: 8.333%;
  flex-basis: 8.333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--2\@sm {
  width: calc(16.6667 * 1% - 16px * 2);
  flex-basis: calc(16.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--2\@sm {
  width: 16.6667%;
  flex-basis: 16.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--3\@sm {
  width: calc(25 * 1% - 16px * 2);
  flex-basis: calc(25 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--3\@sm {
  width: 25%;
  flex-basis: 25%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--4\@sm {
  width: calc(33.3333 * 1% - 16px * 2);
  flex-basis: calc(33.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--4\@sm {
  width: 33.3333%;
  flex-basis: 33.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--5\@sm {
  width: calc(41.6667 * 1% - 16px * 2);
  flex-basis: calc(41.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--5\@sm {
  width: 41.6667%;
  flex-basis: 41.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--6\@sm {
  width: calc(50 * 1% - 16px * 2);
  flex-basis: calc(50 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--6\@sm {
  width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--7\@sm {
  width: calc(58.3333 * 1% - 16px * 2);
  flex-basis: calc(58.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--7\@sm {
  width: 58.3333%;
  flex-basis: 58.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--8\@sm {
  width: calc(66.6667 * 1% - 16px * 2);
  flex-basis: calc(66.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--8\@sm {
  width: 66.6667%;
  flex-basis: 66.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--9\@sm {
  width: calc(75 * 1% - 16px * 2);
  flex-basis: calc(75 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--9\@sm {
  width: 75%;
  flex-basis: 75%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--10\@sm {
  width: calc(83.3333 * 1% - 16px * 2);
  flex-basis: calc(83.3333 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--10\@sm {
  width: 83.3333%;
  flex-basis: 83.3333%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--11\@sm {
  width: calc(91.6667 * 1% - 16px * 2);
  flex-basis: calc(91.6667 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--11\@sm {
  width: 91.6667%;
  flex-basis: 91.6667%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--12\@sm {
  width: calc(100 * 1% - 16px * 2);
  flex-basis: calc(100 * 1% - 16px * 2);
  flex-grow: 0;
}

.bux-grid--no-gutters > .bux-grid__cell--12\@sm {
  width: 100%;
  flex-basis: 100%;
  flex-grow: 0;
  margin-right: 0;
  margin-left: 0;
}

.bux-grid > .bux-grid__cell--auto\@sm {
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .bux-grid > .bux-grid__cell--1\@md {
    width: calc(8.333 * 1% - 16px * 2);
    flex-basis: calc(8.333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--1\@md {
    width: 8.333%;
    flex-basis: 8.333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--2\@md {
    width: calc(16.6667 * 1% - 16px * 2);
    flex-basis: calc(16.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--2\@md {
    width: 16.6667%;
    flex-basis: 16.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--3\@md {
    width: calc(25 * 1% - 16px * 2);
    flex-basis: calc(25 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--3\@md {
    width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--4\@md {
    width: calc(33.3333 * 1% - 16px * 2);
    flex-basis: calc(33.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--4\@md {
    width: 33.3333%;
    flex-basis: 33.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--5\@md {
    width: calc(41.6667 * 1% - 16px * 2);
    flex-basis: calc(41.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--5\@md {
    width: 41.6667%;
    flex-basis: 41.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--6\@md {
    width: calc(50 * 1% - 16px * 2);
    flex-basis: calc(50 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--6\@md {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--7\@md {
    width: calc(58.3333 * 1% - 16px * 2);
    flex-basis: calc(58.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--7\@md {
    width: 58.3333%;
    flex-basis: 58.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--8\@md {
    width: calc(66.6667 * 1% - 16px * 2);
    flex-basis: calc(66.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--8\@md {
    width: 66.6667%;
    flex-basis: 66.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--9\@md {
    width: calc(75 * 1% - 16px * 2);
    flex-basis: calc(75 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--9\@md {
    width: 75%;
    flex-basis: 75%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--10\@md {
    width: calc(83.3333 * 1% - 16px * 2);
    flex-basis: calc(83.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--10\@md {
    width: 83.3333%;
    flex-basis: 83.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--11\@md {
    width: calc(91.6667 * 1% - 16px * 2);
    flex-basis: calc(91.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--11\@md {
    width: 91.6667%;
    flex-basis: 91.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--12\@md {
    width: calc(100 * 1% - 16px * 2);
    flex-basis: calc(100 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--12\@md {
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--auto\@md {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 960px) {
  .bux-grid > .bux-grid__cell--1\@lg {
    width: calc(8.333 * 1% - 16px * 2);
    flex-basis: calc(8.333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--1\@lg {
    width: 8.333%;
    flex-basis: 8.333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--2\@lg {
    width: calc(16.6667 * 1% - 16px * 2);
    flex-basis: calc(16.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--2\@lg {
    width: 16.6667%;
    flex-basis: 16.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--3\@lg {
    width: calc(25 * 1% - 16px * 2);
    flex-basis: calc(25 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--3\@lg {
    width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--4\@lg {
    width: calc(33.3333 * 1% - 16px * 2);
    flex-basis: calc(33.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--4\@lg {
    width: 33.3333%;
    flex-basis: 33.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--5\@lg {
    width: calc(41.6667 * 1% - 16px * 2);
    flex-basis: calc(41.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--5\@lg {
    width: 41.6667%;
    flex-basis: 41.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--6\@lg {
    width: calc(50 * 1% - 16px * 2);
    flex-basis: calc(50 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--6\@lg {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--7\@lg {
    width: calc(58.3333 * 1% - 16px * 2);
    flex-basis: calc(58.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--7\@lg {
    width: 58.3333%;
    flex-basis: 58.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--8\@lg {
    width: calc(66.6667 * 1% - 16px * 2);
    flex-basis: calc(66.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--8\@lg {
    width: 66.6667%;
    flex-basis: 66.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--9\@lg {
    width: calc(75 * 1% - 16px * 2);
    flex-basis: calc(75 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--9\@lg {
    width: 75%;
    flex-basis: 75%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--10\@lg {
    width: calc(83.3333 * 1% - 16px * 2);
    flex-basis: calc(83.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--10\@lg {
    width: 83.3333%;
    flex-basis: 83.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--11\@lg {
    width: calc(91.6667 * 1% - 16px * 2);
    flex-basis: calc(91.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--11\@lg {
    width: 91.6667%;
    flex-basis: 91.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--12\@lg {
    width: calc(100 * 1% - 16px * 2);
    flex-basis: calc(100 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--12\@lg {
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--auto\@lg {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1216px) {
  .bux-grid > .bux-grid__cell--1\@xl {
    width: calc(8.333 * 1% - 16px * 2);
    flex-basis: calc(8.333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--1\@xl {
    width: 8.333%;
    flex-basis: 8.333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--2\@xl {
    width: calc(16.6667 * 1% - 16px * 2);
    flex-basis: calc(16.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--2\@xl {
    width: 16.6667%;
    flex-basis: 16.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--3\@xl {
    width: calc(25 * 1% - 16px * 2);
    flex-basis: calc(25 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--3\@xl {
    width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--4\@xl {
    width: calc(33.3333 * 1% - 16px * 2);
    flex-basis: calc(33.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--4\@xl {
    width: 33.3333%;
    flex-basis: 33.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--5\@xl {
    width: calc(41.6667 * 1% - 16px * 2);
    flex-basis: calc(41.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--5\@xl {
    width: 41.6667%;
    flex-basis: 41.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--6\@xl {
    width: calc(50 * 1% - 16px * 2);
    flex-basis: calc(50 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--6\@xl {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--7\@xl {
    width: calc(58.3333 * 1% - 16px * 2);
    flex-basis: calc(58.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--7\@xl {
    width: 58.3333%;
    flex-basis: 58.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--8\@xl {
    width: calc(66.6667 * 1% - 16px * 2);
    flex-basis: calc(66.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--8\@xl {
    width: 66.6667%;
    flex-basis: 66.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--9\@xl {
    width: calc(75 * 1% - 16px * 2);
    flex-basis: calc(75 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--9\@xl {
    width: 75%;
    flex-basis: 75%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--10\@xl {
    width: calc(83.3333 * 1% - 16px * 2);
    flex-basis: calc(83.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--10\@xl {
    width: 83.3333%;
    flex-basis: 83.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--11\@xl {
    width: calc(91.6667 * 1% - 16px * 2);
    flex-basis: calc(91.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--11\@xl {
    width: 91.6667%;
    flex-basis: 91.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--12\@xl {
    width: calc(100 * 1% - 16px * 2);
    flex-basis: calc(100 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--12\@xl {
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--auto\@xl {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .bux-grid > .bux-grid__cell--1\@xxl {
    width: calc(8.333 * 1% - 16px * 2);
    flex-basis: calc(8.333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--1\@xxl {
    width: 8.333%;
    flex-basis: 8.333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--2\@xxl {
    width: calc(16.6667 * 1% - 16px * 2);
    flex-basis: calc(16.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--2\@xxl {
    width: 16.6667%;
    flex-basis: 16.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--3\@xxl {
    width: calc(25 * 1% - 16px * 2);
    flex-basis: calc(25 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--3\@xxl {
    width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--4\@xxl {
    width: calc(33.3333 * 1% - 16px * 2);
    flex-basis: calc(33.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--4\@xxl {
    width: 33.3333%;
    flex-basis: 33.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--5\@xxl {
    width: calc(41.6667 * 1% - 16px * 2);
    flex-basis: calc(41.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--5\@xxl {
    width: 41.6667%;
    flex-basis: 41.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--6\@xxl {
    width: calc(50 * 1% - 16px * 2);
    flex-basis: calc(50 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--6\@xxl {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--7\@xxl {
    width: calc(58.3333 * 1% - 16px * 2);
    flex-basis: calc(58.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--7\@xxl {
    width: 58.3333%;
    flex-basis: 58.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--8\@xxl {
    width: calc(66.6667 * 1% - 16px * 2);
    flex-basis: calc(66.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--8\@xxl {
    width: 66.6667%;
    flex-basis: 66.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--9\@xxl {
    width: calc(75 * 1% - 16px * 2);
    flex-basis: calc(75 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--9\@xxl {
    width: 75%;
    flex-basis: 75%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--10\@xxl {
    width: calc(83.3333 * 1% - 16px * 2);
    flex-basis: calc(83.3333 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--10\@xxl {
    width: 83.3333%;
    flex-basis: 83.3333%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--11\@xxl {
    width: calc(91.6667 * 1% - 16px * 2);
    flex-basis: calc(91.6667 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--11\@xxl {
    width: 91.6667%;
    flex-basis: 91.6667%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--12\@xxl {
    width: calc(100 * 1% - 16px * 2);
    flex-basis: calc(100 * 1% - 16px * 2);
    flex-grow: 0;
  }
  .bux-grid--no-gutters > .bux-grid__cell--12\@xxl {
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .bux-grid > .bux-grid__cell--auto\@xxl {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
}
.bux-ts-xs {
  font-size: 0.75rem !important;
}

.bux-ts-sm {
  font-size: 0.875rem !important;
}

.bux-ts-base {
  font-size: 1rem !important;
}

.bux-ts-18 {
  font-size: 1.125rem !important;
}

.bux-ts-20 {
  font-size: 1.25rem !important;
}

.bux-ts-22 {
  font-size: 1.375rem !important;
}

.bux-ts-md {
  font-size: 1.5rem !important;
}

.bux-ts-28 {
  font-size: 1.75rem !important;
}

.bux-ts-lg {
  font-size: 2rem !important;
}

.bux-ts-36 {
  font-size: 2.25rem !important;
}

.bux-ts-xl {
  font-size: 2.5rem !important;
}

.bux-ts-xxl {
  font-size: 3rem !important;
}

.bux-ts-60 {
  font-size: 3.75rem !important;
}

.bux-small {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 16px;
}

.bux-pipe {
  display: inline-block;
  padding: 0 4px;
}

span[class^=icon-]:before, span[class*=" icon-"]:before {
  font-family: bux-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

html {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #212325;
}

input {
  border: 1px solid #a7b1b7;
  border-radius: 0;
}
input:focus {
  outline: none;
  border: 2px solid #3492b8;
}

/* inject: /dist/components-list.txt */
.bux-accordion {
  border-bottom: 2px solid #eff1f2;
}

.bux-accordion__heading {
  margin-bottom: 0;
}

.bux-accordion__trigger {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: #212325;
  background-color: #fff;
  border-top: 2px solid #eff1f2;
  padding: 16px;
  width: 100%;
  text-align: left;
  position: relative;
}
.bux-accordion__trigger:focus, .bux-accordion__trigger:hover {
  color: #212325;
  background: #f6f7f8;
  border: none;
  border-top: 2px solid #eff1f2;
}
.bux-accordion__trigger:focus {
  outline: 2px solid #3492b8;
  outline-offset: -2px;
}

.bux-accordion__trigger[aria-expanded=true] {
  background: #fff;
}
.bux-accordion__trigger[aria-expanded=true]:hover {
  background: #f6f7f8;
}

.bux-accordion__title {
  font-size: 1rem;
  display: block;
  pointer-events: none;
  font-weight: 700;
  padding-left: 1.625rem;
}

.bux-accordion__icon:before {
  position: absolute;
  color: #ba0c2f;
  font-size: 1rem;
  left: 16px;
  top: 36%;
  content: "\f005";
  font-family: "bux-icons";
}

.bux-accordion__trigger[aria-expanded=true] .bux-accordion__icon:before {
  content: "\f007";
}

.bux-accordion__panel {
  margin: 0;
  padding: 0.5rem 1rem 1.5rem 2.75rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  background-color: #fff;
}
.bux-accordion__panel p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.bux-accordion__panel[hidden] {
  display: none;
}

/* Fix for accordion list font family */
.bux-accordion ul.bux-list-ul {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.bux-details {
  width: 100%;
  text-align: left;
}

.bux-details__summary {
  list-style: none;
  border-top: none;
  border-bottom: 2px solid #eff1f2;
  padding: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
}
.bux-details__summary:focus, .bux-details__summary:hover {
  background: #f6f7f8;
}
.bux-details__summary:focus {
  outline-offset: -2px;
  outline: 2px solid #3492b8;
}
.bux-details__summary:before {
  position: relative;
  top: 2px;
  margin-right: 0.625rem;
  color: #ba0c2f;
  font-size: 1rem;
  content: "\f005";
  font-family: "bux-icons";
}

.bux-details__wrapper .bux-detail:first-child .bux-details__summary {
  border-top: 2px solid #eff1f2;
}

.bux-details__inner {
  padding: 0.5rem 1rem 1.5rem 2.75rem;
}
.bux-details__inner ul.bux-list-ul {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}
.bux-details__inner p:last-child {
  margin-bottom: 0;
}

.bux-details[open] {
  border-bottom: 2px solid #eff1f2;
}
.bux-details[open] .bux-details__summary {
  background: #fff;
  border-bottom: none;
}
.bux-details[open] .bux-details__summary:hover {
  background: #f6f7f8;
}
.bux-details[open] .bux-details__summary:before {
  content: "\f007";
}

*::-webkit-details-marker {
  display: none;
}

.bux-alert.bux-alert--info {
  position: relative;
  background-color: #c6e9f8;
  color: #000;
  display: flex;
  padding: 8px 16px;
}
.bux-alert.bux-alert--info .bux-alert__icon {
  line-height: 1;
  margin-right: 12px;
}
.bux-alert.bux-alert--info .bux-alert__icon:after {
  content: "\f104";
  font-family: "bux-icons";
  font-size: 1.5rem;
  color: #2E7FA1;
}
.bux-alert.bux-alert--info .bux-alert__message-title {
  color: #000;
  font-size: 1.125rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 1.5rem;
}
.bux-alert.bux-alert--info .bux-alert__message-text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
.bux-alert.bux-alert--info .bux-alert__dismiss {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  position: absolute;
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
}
.bux-alert.bux-alert--info .bux-alert__dismiss:after {
  content: "\f105";
  font-family: "bux-icons";
  font-size: 1rem;
  color: #000;
}
.bux-alert.bux-alert--info .bux-alert__dismiss:focus {
  outline: 2px solid #3492b8;
}
.bux-alert.bux-alert--info a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-alert.bux-alert--info a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-alert.bux-alert--info a:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-alert.bux-alert--info a:focus {
  color: #ba0c2f;
  background-color: #fff;
}
.bux-alert.bux-alert--info a:visited {
  color: #660099;
  border-bottom-color: #660099;
}
.bux-alert.bux-alert--success {
  position: relative;
  background-color: #dcf5d0;
  color: #000;
  display: flex;
  padding: 8px 16px;
}
.bux-alert.bux-alert--success .bux-alert__icon {
  line-height: 1;
  margin-right: 12px;
}
.bux-alert.bux-alert--success .bux-alert__icon:after {
  content: "\f102";
  font-family: "bux-icons";
  font-size: 1.5rem;
  color: #55853D;
}
.bux-alert.bux-alert--success .bux-alert__message-title {
  color: #000;
  font-size: 1.125rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 1.5rem;
}
.bux-alert.bux-alert--success .bux-alert__message-text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
.bux-alert.bux-alert--success .bux-alert__dismiss {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  position: absolute;
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
}
.bux-alert.bux-alert--success .bux-alert__dismiss:after {
  content: "\f105";
  font-family: "bux-icons";
  font-size: 1rem;
  color: #000;
}
.bux-alert.bux-alert--success .bux-alert__dismiss:focus {
  outline: 2px solid #3492b8;
}
.bux-alert.bux-alert--success a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-alert.bux-alert--success a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-alert.bux-alert--success a:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-alert.bux-alert--success a:focus {
  color: #ba0c2f;
  background-color: #fff;
}
.bux-alert.bux-alert--success a:visited {
  color: #660099;
  border-bottom-color: #660099;
}
.bux-alert.bux-alert--warning {
  position: relative;
  background-color: #fff0cc;
  color: #000;
  display: flex;
  padding: 8px 16px;
}
.bux-alert.bux-alert--warning .bux-alert__icon {
  line-height: 1;
  margin-right: 12px;
}
.bux-alert.bux-alert--warning .bux-alert__icon:after {
  content: "\f108";
  font-family: "bux-icons";
  font-size: 1.5rem;
  color: #E65F33;
}
.bux-alert.bux-alert--warning .bux-alert__message-title {
  color: #000;
  font-size: 1.125rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 1.5rem;
}
.bux-alert.bux-alert--warning .bux-alert__message-text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
.bux-alert.bux-alert--warning .bux-alert__dismiss {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  position: absolute;
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
}
.bux-alert.bux-alert--warning .bux-alert__dismiss:after {
  content: "\f105";
  font-family: "bux-icons";
  font-size: 1rem;
  color: #000;
}
.bux-alert.bux-alert--warning .bux-alert__dismiss:focus {
  outline: 2px solid #3492b8;
}
.bux-alert.bux-alert--warning a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-alert.bux-alert--warning a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-alert.bux-alert--warning a:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-alert.bux-alert--warning a:focus {
  color: #ba0c2f;
  background-color: #fff;
}
.bux-alert.bux-alert--warning a:visited {
  color: #660099;
  border-bottom-color: #660099;
}
.bux-alert.bux-alert--error {
  position: relative;
  background-color: #ba0c2f;
  color: #fff;
  display: flex;
  padding: 8px 16px;
}
.bux-alert.bux-alert--error .bux-alert__icon {
  line-height: 1;
  margin-right: 12px;
}
.bux-alert.bux-alert--error .bux-alert__icon:after {
  content: "\f106";
  font-family: "bux-icons";
  font-size: 1.5rem;
  color: #fff;
}
.bux-alert.bux-alert--error .bux-alert__message-title {
  color: #fff;
  font-size: 1.125rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 1.5rem;
}
.bux-alert.bux-alert--error .bux-alert__message-text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
.bux-alert.bux-alert--error .bux-alert__dismiss {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  position: absolute;
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
}
.bux-alert.bux-alert--error .bux-alert__dismiss:after {
  content: "\f105";
  font-family: "bux-icons";
  font-size: 1rem;
  color: #fff;
}
.bux-alert.bux-alert--error .bux-alert__dismiss:focus {
  outline: 2px solid #3492b8;
}
.bux-alert.bux-alert--error a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #fff;
  border-bottom-color: #fff;
}
.bux-alert.bux-alert--error a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-alert.bux-alert--error a:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-alert.bux-alert--error a:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}
.bux-alert.bux-alert--error a:visited {
  color: #e7cdf4;
  border-bottom-color: #e7cdf4;
}
.bux-alert.bux-alert--error a:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-alert.bux-alert--error a:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}

.bux-back-to-top {
  display: none;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
  background-color: #fff;
  border: 2px solid #ba0c2f;
  color: #ba0c2f;
  font-weight: 600;
  padding: 0.5rem 1rem;
  align-items: center;
}
.bux-back-to-top:hover {
  background: #3f4443;
  color: #fff;
  border-color: #3f4443;
  cursor: pointer;
}
.bux-back-to-top:focus {
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}
@media (min-width: 640px) {
  .bux-back-to-top {
    bottom: 32px;
  }
}

.bux-back-to-top__text {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
@media (min-width: 640px) {
  .bux-back-to-top__text {
    position: static !important;
    width: inherit !important;
    overflow: visible !important;
    display: block;
    font-size: 1rem;
    padding-bottom: 1.375rem !important;
  }
}

.bux-back-to-top__icon {
  color: #ba0c2f;
  font-size: 1.25rem;
  padding-top: 0.25rem;
}
.bux-back-to-top__icon::before {
  margin-bottom: -0.625rem;
}
@media (min-width: 640px) {
  .bux-back-to-top__icon {
    margin-right: 8px;
  }
}

.bux-back-to-top:hover .bux-back-to-top__icon {
  color: #fff;
}

.bux-breadcrumb {
  display: block;
  margin: 16px 0;
  line-height: 24px;
}

.bux-breadcrumb__item {
  position: relative;
  display: inline-block;
  color: #646a6e;
  font-weight: 400;
  font-size: 0.875rem;
}
.bux-breadcrumb__item::after {
  font-size: 10px;
  content: "\f005";
  font-family: "bux-icons";
  margin-right: 4px;
}

.bux-breadcrumb__item--current::after {
  content: "";
}

.bux-breadcrumb__link {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
  color: #ba0c2f;
  margin-right: 4px;
}
.bux-breadcrumb__link:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-breadcrumb__link:hover {
  color: #212325;
  border-bottom-color: #212325;
  background-color: #eff1f2;
}
.bux-breadcrumb__link:focus {
  color: #ba0c2f;
}

.bux-breadcrumb--alt {
  background: #bfc6cb;
  border-radius: 4px;
  padding: 8px;
}
.bux-breadcrumb--alt .bux-breadcrumb__link {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-breadcrumb--alt .bux-breadcrumb__link:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-breadcrumb--alt .bux-breadcrumb__link:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-breadcrumb--alt .bux-breadcrumb__link:focus {
  color: #ba0c2f;
  background-color: #fff;
}

.bux-buckeye-alert {
  background-color: #ba0c2f;
  padding: 16px 12px;
}
.bux-buckeye-alert h2 {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.bux-buckeye-alert a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #fff;
  border-bottom-color: #fff;
}
.bux-buckeye-alert a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-buckeye-alert a:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-buckeye-alert a:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}
.bux-buckeye-alert p {
  color: #fff;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}
.bux-buckeye-alert p:last-child {
  margin-bottom: 0;
}
.bux-buckeye-alert .bux-buckeye-alert-container {
  display: flex;
}
.bux-buckeye-alert .bux-buckeye-alert-container .bux-buckeye-alert-container__icon {
  line-height: 1;
  margin-right: 16px;
}
.bux-buckeye-alert .bux-buckeye-alert-container .bux-buckeye-alert-container__icon:after {
  content: "\f301";
  font-family: "bux-icons";
  font-size: 1.5rem;
  color: #fff;
}

button:not([class]),
.bux-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #ba0c2f;
  color: #fff;
  border: 2px solid #ba0c2f;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}
button:not([class]):hover,
.bux-button:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
button:not([class]):focus, button:not([class]):active,
.bux-button:focus,
.bux-button:active {
  background-color: #ba0c2f;
  color: #fff;
  border: 2px solid #ba0c2f;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--disabled {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #eff1f2;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: not-allowed;
}
.bux-button--disabled:hover {
  background-color: #eff1f2;
  color: #868e92;
  border-color: #eff1f2;
}
.bux-button--disabled:focus, .bux-button--disabled:active {
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #eff1f2;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--alt {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #fff;
  color: #ba0c2f;
  border: 2px solid #ba0c2f;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}
.bux-button--alt:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-button--alt:focus, .bux-button--alt:active {
  background-color: #fff;
  color: #ba0c2f;
  border: 2px solid #ba0c2f;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--alt-disabled {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #dfe3e5;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: not-allowed;
}
.bux-button--alt-disabled:hover {
  background-color: #eff1f2;
  color: #868e92;
  border-color: #dfe3e5;
}
.bux-button--alt-disabled:focus, .bux-button--alt-disabled:active {
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #dfe3e5;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--small {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #ba0c2f;
  color: #fff;
  border: 2px solid #ba0c2f;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}
.bux-button--small:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-button--small:focus, .bux-button--small:active {
  background-color: #ba0c2f;
  color: #fff;
  border: 2px solid #ba0c2f;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--small-disabled {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #eff1f2;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: not-allowed;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}
.bux-button--small-disabled:hover {
  background-color: #eff1f2;
  color: #868e92;
  border-color: #eff1f2;
}
.bux-button--small-disabled:focus, .bux-button--small-disabled:active {
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #eff1f2;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--alt-small {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #fff;
  color: #ba0c2f;
  border: 2px solid #ba0c2f;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}
.bux-button--alt-small:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-button--alt-small:focus, .bux-button--alt-small:active {
  background-color: #fff;
  color: #ba0c2f;
  border: 2px solid #ba0c2f;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--alt-small-disabled {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #dfe3e5;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: not-allowed;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}
.bux-button--alt-small-disabled:hover {
  background-color: #eff1f2;
  color: #868e92;
  border-color: #dfe3e5;
}
.bux-button--alt-small-disabled:focus, .bux-button--alt-small-disabled:active {
  background-color: #eff1f2;
  color: #868e92;
  border: 2px solid #dfe3e5;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button--icon {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #eff1f2;
  color: #ba0c2f;
  border: 2px solid #eff1f2;
  padding: 0.625rem 1.25rem;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.bux-button--icon:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-button--icon:focus, .bux-button--icon:active {
  background-color: #eff1f2;
  color: #ba0c2f;
  border: 2px solid #eff1f2;
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-button__icon {
  margin-right: 16px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
}
.bux-button__icon .icon {
  padding-top: 9px;
  font-size: 28px;
}

a.bux-button {
  display: inline-block;
}

.bux-card-carousel {
  position: relative;
  overflow: hidden;
  margin: 32px 0;
}
.bux-card-carousel__container {
  display: flex;
  overflow-x: scroll;
  scroll-padding: 0 64px;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.bux-card-carousel__container .bux-card {
  scroll-snap-align: start;
  flex: 0 0 calc(100% - 128px);
  margin-right: 32px;
}
.bux-card-carousel__container .bux-card:first-child {
  margin-left: 64px;
}
.bux-card-carousel__container .bux-card:last-child {
  margin-right: 64px;
}
@media (min-width: 640px) {
  .bux-card-carousel__container .bux-card {
    flex: 0 0 calc(33.333% - 64px);
  }
}
@media (min-width: 960px) {
  .bux-card-carousel__container .bux-card {
    flex: 0 0 calc(25% - 56px);
  }
}
.bux-card-carousel__container::-webkit-scrollbar {
  display: none;
}
.bux-card-carousel__nav {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 44px;
  font-family: bux-icons;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bux-card-carousel__nav--left-column {
  left: 0;
}
.bux-card-carousel__nav--right-column {
  right: 0;
}
.bux-card-carousel__nav--left, .bux-card-carousel__nav--right {
  height: 44px;
  width: 44px;
  background: #fff;
  color: #ba0c2f;
  border: solid 2px #ba0c2f;
}
.bux-card-carousel__nav--left:hover, .bux-card-carousel__nav--right:hover {
  background: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-card-carousel__nav--left:focus, .bux-card-carousel__nav--right:focus {
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-card-carousel__nav--left::before {
  content: "\f004";
  margin: auto;
}
.bux-card-carousel__nav--right::before {
  content: "\f005";
  margin: auto;
}

.bux-card {
  border-top: 4px solid #ba0c2f;
  color: #212325;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.bux-card--gray {
  background-color: #f6f7f8;
}

.bux-card__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

bux-card .bux-image {
  object-fit: cover;
  aspect-ratio: 16/9;
}

.bux-card__content {
  padding: 24px;
  border: 2px solid #f6f7f8;
  border-top: none;
  position: relative;
  z-index: 2;
  pointer-events: none;
  flex: 1;
}

.bux-card--no-border .bux-card__content,
.bux-card--storytelling .bux-card__content,
.bux-card--horizontal .bux-card__content,
.bux-card--horizontal-storytelling .bux-card__content {
  border: 0;
  padding: 0;
  padding-top: 24px;
  padding-right: 24px;
}

.bux-card--storytelling,
.bux-card--horizontal-storytelling {
  border-top: none;
}
.bux-card--storytelling .bux-card__heading,
.bux-card--horizontal-storytelling .bux-card__heading {
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.bux-card__read-time {
  font-size: 1rem;
  color: #646a6e;
  margin-top: 8px;
}
.bux-card__read-time::before {
  content: "\f302";
  font-family: "bux-icons";
  font-size: 0.875rem;
  margin-right: 6px;
  margin-top: 1.5px;
  float: left;
}

*.bux-card__heading {
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.bux-card__body {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 16px;
}

.bux-card__cta {
  margin-top: 8px;
  position: relative;
  z-index: 2;
}
.bux-card__cta a {
  color: #212325;
  pointer-events: all;
  text-decoration: none;
  display: table;
}
.bux-card__cta a::after {
  content: "\f005";
  font-size: 0.875rem;
  font-family: "bux-icons";
  font-weight: 600;
  color: #ba0c2f;
  padding-left: 8px;
  transition-duration: 0.05s;
  display: table-cell;
  vertical-align: middle;
}
.bux-card__cta a:hover {
  color: #ba0c2f;
}
.bux-card__cta a:hover::after {
  padding-left: 16px;
  transition-duration: 0.05s;
}
.bux-card__cta a:focus {
  outline: 2px solid #3492b8;
  outline-offset: 4px;
}

.bux-card--linked-headline .bux-card__body,
.bux-card--storytelling .bux-card__body,
.bux-card--horizontal-storytelling .bux-card__body {
  margin-bottom: 8px;
}

.bux-card--linked-headline .bux-card__heading {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 24px;
}

.bux-card__heading--link {
  text-decoration: none;
  color: #212325;
  pointer-events: all;
}
.bux-card__heading--link::after {
  content: "\f005";
  font-size: 1.5rem;
  font-family: "bux-icons";
  color: #ba0c2f;
  transition-duration: 0.05s;
  float: right;
  padding-right: 24px;
  padding-top: 24px;
  position: absolute;
  top: 0;
  right: 0;
}
.bux-card__heading--link:focus {
  outline: 2px solid #3492b8;
}

.bux-card__heading--storytelling-link {
  text-decoration: none;
  color: #212325;
  pointer-events: all;
}
.bux-card__heading--storytelling-link:focus {
  outline: 2px solid #3492b8;
}

.bux-card:hover .bux-card__heading--link {
  color: #ba0c2f;
  text-decoration: none;
}
.bux-card:hover .bux-card__heading--link::after {
  transition-duration: 0.05s;
  padding-right: 16px;
}
.bux-card:hover .bux-card__heading--storytelling-link {
  text-decoration: none;
}

.bux-card--horizontal-storytelling:hover .bux-card__heading span {
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  color: #212325;
}

.bux-card--storytelling:hover .bux-card__heading span,
.bux-card--horizontal-storytelling:hover .bux-card__heading span {
  color: #212325;
  text-decoration: none;
  box-shadow: inset 0 -3px 0 0 #212325;
  text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff;
}

.bux-card--storytelling .bux-card__heading span:hover,
.bux-card--horizontal-storytelling .bux-card__heading span:hover {
  color: #212325;
}

.bux-card__taxonomy {
  color: #ba0c2f;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 8px;
  display: inline-block;
}

.bux-card--featured {
  display: block;
  background-color: #fff;
  max-width: 100%;
  margin-right: 0;
}
.bux-card--featured .bux-card__content {
  padding: 32px;
}
.bux-card--featured .bux-button {
  text-decoration: none;
  display: inline-block;
}
.bux-card--featured:hover .bux-button {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}

@media (min-width: 640px) {
  .bux-card--horizontal,
  .bux-card--horizontal-storytelling {
    border-top: none;
    flex-direction: row;
    max-width: 100%;
  }
  .bux-card--horizontal .bux-card__heading,
  .bux-card--horizontal-storytelling .bux-card__heading {
    font-size: 1.5rem;
  }
  .bux-card--horizontal .bux-image,
  .bux-card--horizontal-storytelling .bux-image {
    max-width: 33%;
    margin-right: 32px;
    object-fit: cover;
  }
  .bux-card--horizontal .bux-card__content,
  .bux-card--horizontal-storytelling .bux-card__content {
    padding-top: 0;
  }
  .bux-card--horizontal-storytelling {
    border-bottom: 2px solid #dfe3e5;
    padding-bottom: 32px;
  }
  .bux-card--horizontal-storytelling .bux-card__content {
    padding-left: 0;
    border: 0;
  }
  .bux-card--horizontal-storytelling .bux-card__body {
    margin-bottom: 8px;
  }
}
.bux-colors__group-label {
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0.75rem 0 0.25rem;
}

.bux-colors__group {
  display: flex;
  flex-wrap: wrap;
}

.bux-colors__item {
  width: 8.75rem;
  margin-right: 16px;
  margin-bottom: 32px;
  border: 1px solid #dfe3e5;
}
.bux-colors__item:last-child {
  margin-right: 0;
}

.bux-colors__swatch {
  height: 5rem;
}

.bux-colors__label {
  padding: 4px 8px;
}

.bux-colors__code {
  display: block;
  font-size: 0.75rem;
  font-family: "Source Code Pro", monospace;
}

.bux-cta-collection {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
.bux-cta-collection ul {
  padding: 0;
  margin: -1.75rem 0 0;
  text-align: center;
}
.bux-cta-collection ul li {
  display: block;
  text-align: center;
  line-height: 1.75rem;
  margin-top: 1.75rem;
}
.bux-cta-collection__link {
  text-decoration: none;
  color: #ba0c2f;
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 700;
}
.bux-cta-collection__link:hover {
  background-color: #eff1f2;
  color: #212325;
}
.bux-cta-collection__link:focus {
  outline: 2px solid #3492b8;
}
.bux-cta-collection .icon {
  display: none;
}
@media (min-width: 640px) {
  .bux-cta-collection {
    padding: 48px 0;
  }
  .bux-cta-collection ul li {
    display: inline-block;
    padding: 0 48px;
    border-right: 2px solid #dfe3e5;
  }
  .bux-cta-collection ul li:last-child {
    border-right: none;
  }
  .bux-cta-collection__link {
    font-size: 1.5rem;
    line-height: 32px;
  }
  .bux-cta-collection .icon {
    display: inline-block;
    color: #ba0c2f;
    padding-right: 12px;
    font-size: 1.875rem;
    translate: 0 7px;
  }
}

.bux-cta-collection--gray {
  background-color: #eff1f2;
}
.bux-cta-collection--gray a:hover {
  background-color: #fff;
}

@media (min-width: 640px) {
  .bux-cta-collection--standard-icons .bux-cta-collection__link {
    display: flex;
    align-items: center;
  }
}
.bux-cta-collection--standard-icons .icon {
  font-size: 3.625rem;
  height: 4.5rem;
  translate: 0;
  width: 4.5rem;
}
.bux-cta-collection--standard-icons .icon::before {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.bux-cta-collection--standard-icons .standard-icon {
  display: none;
}
@media (min-width: 640px) {
  .bux-cta-collection--standard-icons .standard-icon {
    display: block;
    height: 4.5rem;
    margin: 0 16px 0 0;
    width: 4.5rem;
  }
}

.bux-doc-pagination {
  display: flex;
  justify-content: space-between;
}

.bux-doc-pagination__item {
  width: 50%;
}

.bux-doc-pagination__item--prev {
  padding-right: 8px;
  text-align: left;
}

.bux-doc-pagination__item--next {
  padding-left: 8px;
  text-align: right;
}

.bux-doc-pagination__link {
  display: block;
  border: 2px solid #f6f7f8;
  padding: 24px;
  font-weight: 600;
  text-decoration: none;
}
.bux-doc-pagination__link:hover {
  border-color: #3f4443;
  background-color: #3f4443;
}
.bux-doc-pagination__link:hover .bux-doc-pagination__label {
  color: #fff;
}
.bux-doc-pagination__link:hover .bux-doc-pagination__label:before, .bux-doc-pagination__link:hover .bux-doc-pagination__label:after {
  color: #fff;
}
.bux-doc-pagination__link:focus {
  outline: 2px solid #3492b8;
  outline-offset: 1px;
}

.bux-doc-pagination__label {
  font-size: 1rem;
  line-height: 24px;
  color: #ba0c2f;
}
.bux-doc-pagination__label:before, .bux-doc-pagination__label:after {
  font-family: "bux-icons";
  font-size: 1.25rem;
  color: #ba0c2f;
  vertical-align: -0.25rem;
}

.bux-doc-pagination__label--prev:before {
  content: "\f004";
  margin-right: 8px;
}

.bux-doc-pagination__label--next:after {
  content: "\f005";
  margin-left: 8px;
}

.bux-doc-pagination__item--next:first-child {
  margin-left: auto;
}

@media (min-width: 960px) {
  .bux-factoid {
    display: flex;
  }
}

.bux-factoid__item {
  padding: 12px 16px;
  display: block;
}
.bux-factoid__item:last-child {
  border-right: none;
}
@media (min-width: 960px) {
  .bux-factoid__item {
    border-right: 2px solid #dfe3e5;
    padding: 0 16px;
    flex: 1 1 0;
  }
}

.bux-factoid__stat {
  display: block;
  color: #ba0c2f;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  line-height: 4.125rem;
  text-align: center;
}
@media (min-width: 960px) {
  .bux-factoid__stat {
    font-size: 4.375rem;
    line-height: 6rem;
  }
}
.bux-factoid__stat sup {
  font-size: 2.5rem;
  line-height: 1;
}

.bux-factoid__text {
  display: block;
  color: #212325;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}
@media (min-width: 960px) {
  .bux-factoid__text {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

.bux-featured-card {
  flex-direction: column-reverse;
}
@media (min-width: 640px) {
  .bux-featured-card {
    flex-direction: row;
  }
}
@media (min-width: 640px) {
  .bux-featured-card .bux-featured-card--image {
    flex-basis: 66.6%;
  }
}
.bux-featured-card .bux-featured-card--image img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
@media (min-width: 640px) {
  .bux-featured-card .bux-featured-card--image img {
    width: 100%;
  }
}
.bux-featured-card .bux-featured-card--wrapper {
  align-self: center;
  max-width: 100%;
  margin-left: 0;
}
@media (min-width: 640px) {
  .bux-featured-card .bux-featured-card--wrapper {
    margin-right: -8.33%;
  }
}
@media (min-width: 640px) {
  .bux-featured-card--image-left {
    flex-direction: row-reverse;
  }
  .bux-featured-card--image-left .bux-featured-card--wrapper {
    margin-left: -8.33%;
    margin-right: 0;
  }
}

.bux-footer,
.bux-footer--light {
  min-height: 200px;
  padding: 24px 0;
  background-color: #f6f7f8;
  color: #212325;
}
.bux-footer .bux-link,
.bux-footer--light .bux-link {
  color: #212325;
  border-bottom: 1px solid #212325;
}
.bux-footer .bux-link:hover,
.bux-footer--light .bux-link:hover {
  background-color: #fff;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-footer .bux-link:focus,
.bux-footer--light .bux-link:focus {
  outline: 2px solid #3492b8;
  background-color: #fff;
  color: #ba0c2f;
}
.bux-footer hr,
.bux-footer--light hr {
  border: none;
  border-top: 2px solid #dfe3e5;
  margin-bottom: 16px;
}
.bux-footer .bux-footer__col--left,
.bux-footer--light .bux-footer__col--left {
  margin-bottom: 48px;
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__col--left,
  .bux-footer--light .bux-footer__col--left {
    margin-bottom: 0;
    padding-right: 32px;
  }
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__col--right,
  .bux-footer--light .bux-footer__col--right {
    padding-left: 32px;
  }
}
.bux-footer .bux-footer__logo,
.bux-footer--light .bux-footer__logo {
  margin-bottom: 16px;
}
.bux-footer .bux-footer__logo a,
.bux-footer--light .bux-footer__logo a {
  display: inline-block;
  width: 270px;
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__logo a,
  .bux-footer--light .bux-footer__logo a {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .bux-footer .bux-footer__logo a,
  .bux-footer--light .bux-footer__logo a {
    width: 290px;
  }
}
.bux-footer .bux-footer__logo a:focus,
.bux-footer--light .bux-footer__logo a:focus {
  outline: 2px solid #3492b8;
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__contact,
  .bux-footer--light .bux-footer__contact {
    max-width: 300px;
  }
}
.bux-footer .bux-footer__contact p,
.bux-footer--light .bux-footer__contact p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 0;
  line-height: 1.375;
}
.bux-footer .bux-footer__contact--address,
.bux-footer--light .bux-footer__contact--address {
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.bux-footer .bux_footer__site-name,
.bux-footer--light .bux_footer__site-name {
  font-weight: 700;
}
.bux-footer p.bux_footer__email,
.bux-footer p.bux_footer__phone,
.bux-footer p.bux_footer__fax,
.bux-footer--light p.bux_footer__email,
.bux-footer--light p.bux_footer__phone,
.bux-footer--light p.bux_footer__fax {
  margin: 8px 0;
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__social,
  .bux-footer--light .bux-footer__social {
    text-align: right;
  }
}
.bux-footer .bux-footer__fine-print,
.bux-footer--light .bux-footer__fine-print {
  margin-top: 16px;
  text-align: left;
}
.bux-footer .bux-footer__fine-print div,
.bux-footer .bux-footer__fine-print p,
.bux-footer--light .bux-footer__fine-print div,
.bux-footer--light .bux-footer__fine-print p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__fine-print,
  .bux-footer--light .bux-footer__fine-print {
    text-align: right;
  }
}
@media (min-width: 640px) {
  .bux-footer .bux-footer__ada,
  .bux-footer--light .bux-footer__ada {
    max-width: 400px;
    float: right;
  }
}
.bux-footer .bux-footer__links,
.bux-footer--light .bux-footer__links {
  clear: both;
  padding-top: 16px;
}
.bux-footer .bux-footer__links ul,
.bux-footer--light .bux-footer__links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bux-footer .bux-footer__copyright,
.bux-footer--light .bux-footer__copyright {
  clear: both;
  margin-top: 16px;
}

.bux-footer--dark {
  min-height: 200px;
  padding: 24px 0;
  background-color: #212325;
  color: #fff;
}
.bux-footer--dark .bux-link {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.bux-footer--dark .bux-link:hover {
  background-color: #646a6e;
  color: #fff;
  border-bottom-color: #fff;
}
.bux-footer--dark .bux-link:focus {
  outline: 2px solid #3492b8;
  background-color: #646a6e;
  color: #fff;
}
.bux-footer--dark hr {
  border: none;
  border-top: 2px solid #868e92;
  margin-bottom: 16px;
}
.bux-footer--dark .bux-footer__col--left {
  margin-bottom: 48px;
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__col--left {
    margin-bottom: 0;
    padding-right: 32px;
  }
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__col--right {
    padding-left: 32px;
  }
}
.bux-footer--dark .bux-footer__logo {
  margin-bottom: 16px;
}
.bux-footer--dark .bux-footer__logo a {
  display: inline-block;
  width: 270px;
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__logo a {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .bux-footer--dark .bux-footer__logo a {
    width: 290px;
  }
}
.bux-footer--dark .bux-footer__logo a:focus {
  outline: 2px solid #3492b8;
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__contact {
    max-width: 300px;
  }
}
.bux-footer--dark .bux-footer__contact p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 0;
  line-height: 1.375;
}
.bux-footer--dark .bux-footer__contact--address {
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.bux-footer--dark .bux_footer__site-name {
  font-weight: 700;
}
.bux-footer--dark p.bux_footer__email,
.bux-footer--dark p.bux_footer__phone,
.bux-footer--dark p.bux_footer__fax {
  margin: 8px 0;
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__social {
    text-align: right;
  }
}
.bux-footer--dark .bux-footer__fine-print {
  margin-top: 16px;
  text-align: left;
}
.bux-footer--dark .bux-footer__fine-print div,
.bux-footer--dark .bux-footer__fine-print p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__fine-print {
    text-align: right;
  }
}
@media (min-width: 640px) {
  .bux-footer--dark .bux-footer__ada {
    max-width: 400px;
    float: right;
  }
}
.bux-footer--dark .bux-footer__links {
  clear: both;
  padding-top: 16px;
}
.bux-footer--dark .bux-footer__links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bux-footer--dark .bux-footer__copyright {
  clear: both;
  margin-top: 16px;
}

.bux-footer--white {
  min-height: 200px;
  padding: 24px 0;
  background-color: #fff;
  color: #212325;
  border-top: 2px solid #dfe3e5;
}
.bux-footer--white .bux-link {
  color: #212325;
  border-bottom: 1px solid #212325;
}
.bux-footer--white .bux-link:hover {
  background-color: #eff1f2;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-footer--white .bux-link:focus {
  outline: 2px solid #3492b8;
  background-color: #eff1f2;
  color: #ba0c2f;
}
.bux-footer--white hr {
  border: none;
  border-top: 2px solid #eff1f2;
  margin-bottom: 16px;
}
.bux-footer--white .bux-footer__col--left {
  margin-bottom: 48px;
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__col--left {
    margin-bottom: 0;
    padding-right: 32px;
  }
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__col--right {
    padding-left: 32px;
  }
}
.bux-footer--white .bux-footer__logo {
  margin-bottom: 16px;
}
.bux-footer--white .bux-footer__logo a {
  display: inline-block;
  width: 270px;
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__logo a {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .bux-footer--white .bux-footer__logo a {
    width: 290px;
  }
}
.bux-footer--white .bux-footer__logo a:focus {
  outline: 2px solid #3492b8;
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__contact {
    max-width: 300px;
  }
}
.bux-footer--white .bux-footer__contact p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 0;
  line-height: 1.375;
}
.bux-footer--white .bux-footer__contact--address {
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.bux-footer--white .bux_footer__site-name {
  font-weight: 700;
}
.bux-footer--white p.bux_footer__email,
.bux-footer--white p.bux_footer__phone,
.bux-footer--white p.bux_footer__fax {
  margin: 8px 0;
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__social {
    text-align: right;
  }
}
.bux-footer--white .bux-footer__fine-print {
  margin-top: 16px;
  text-align: left;
}
.bux-footer--white .bux-footer__fine-print div,
.bux-footer--white .bux-footer__fine-print p {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__fine-print {
    text-align: right;
  }
}
@media (min-width: 640px) {
  .bux-footer--white .bux-footer__ada {
    max-width: 400px;
    float: right;
  }
}
.bux-footer--white .bux-footer__links {
  clear: both;
  padding-top: 16px;
}
.bux-footer--white .bux-footer__links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bux-footer--white .bux-footer__copyright {
  clear: both;
  margin-top: 16px;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #212325 !important;
  border: none !important;
  border-bottom: 1px solid #212325 !important;
  padding: 0 !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
}
#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  cursor: pointer;
  color: #ba0c2f !important;
  background-color: #fff !important;
  border-color: #ba0c2f !important;
}

.bux-checkbox {
  position: relative;
}
.bux-checkbox__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-checkbox__required {
  color: #ba0c2f;
}
.bux-checkbox__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-checkbox__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-checkbox__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-checkbox__legend + * {
  clear: both;
}
.bux-checkbox__input-spacer {
  margin-top: 8px;
}
.bux-checkbox__option {
  display: grid;
  grid-template-columns: 18px auto;
  gap: 12px;
}
.bux-checkbox__option label {
  margin-top: 2px;
}
.bux-checkbox__option + .bux-checkbox__option {
  margin-top: 8px;
}
.bux-checkbox__option input[type=checkbox] {
  appearance: none;
  display: grid;
  place-content: center;
  margin: 3px 3px 3px 0;
  border: solid 2px #212325;
  width: 20px;
  height: 20px;
}
.bux-checkbox__option input[type=checkbox]:before {
  content: "\f101";
  font-family: "bux-icons";
  color: #fff;
  z-index: 99;
  font-size: 11px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}
.bux-checkbox__option input[type=checkbox]:checked:before {
  transform: scale(1);
}
.bux-checkbox__option input[type=checkbox]:checked {
  border-color: #ba0c2f;
  background-color: #ba0c2f;
}
.bux-checkbox__option input[type=checkbox]:focus {
  outline: 2px solid #3492b8;
}
.bux-checkbox__option--disabled, .bux-checkbox__option--disabled label {
  cursor: not-allowed;
}
.bux-checkbox__option--disabled input[type=checkbox],
.bux-checkbox__option--disabled input[type=checkbox]:checked, .bux-checkbox__option--disabled label input[type=checkbox],
.bux-checkbox__option--disabled label input[type=checkbox]:checked {
  border: 2px solid #cfd4d8;
  background-color: #eff1f2;
}
.bux-checkbox__option--disabled input[type=checkbox]:checked:before,
.bux-checkbox__option--disabled input[type=checkbox]:checked:checked:before, .bux-checkbox__option--disabled label input[type=checkbox]:checked:before,
.bux-checkbox__option--disabled label input[type=checkbox]:checked:checked:before {
  content: none;
}
.bux-checkbox__option--disabled input[type=checkbox] + label,
.bux-checkbox__option--disabled input[type=checkbox]:checked + label, .bux-checkbox__option--disabled label input[type=checkbox] + label,
.bux-checkbox__option--disabled label input[type=checkbox]:checked + label {
  color: #bfc6cb;
}
.bux-checkbox__option--disabled input[type=checkbox] + label:before, .bux-checkbox__option--disabled input[type=checkbox] + label:checked:before,
.bux-checkbox__option--disabled input[type=checkbox]:checked + label:before,
.bux-checkbox__option--disabled input[type=checkbox]:checked + label:checked:before, .bux-checkbox__option--disabled label input[type=checkbox] + label:before, .bux-checkbox__option--disabled label input[type=checkbox] + label:checked:before,
.bux-checkbox__option--disabled label input[type=checkbox]:checked + label:before,
.bux-checkbox__option--disabled label input[type=checkbox]:checked + label:checked:before {
  content: none;
}
.bux-checkbox__option--error > input[type=checkbox] {
  background: #ffeff2;
  border-color: #ba0c2f;
}
.bux-checkbox__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
  margin-top: 5px;
}
.bux-checkbox__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}

.bux-form--field-wrapper {
  position: relative;
}

.bux-multi-form {
  position: relative;
}
.bux-multi-form__title, .bux-multi-form__form-title, .bux-multi-form__component {
  margin-bottom: 32px;
}
.bux-multi-form__layout {
  padding-bottom: 48px;
}

.bux-multi-form__progress {
  background: #dfe3e5;
}
.bux-multi-form__progress-filled {
  background: #ba0c2f;
  transition: 400ms all ease-in-out;
}

.bux-multi-form__step-container {
  display: grid;
}
.bux-multi-form__step-label {
  margin-top: 2px;
}
.bux-multi-form__step-icon {
  background-color: #fff;
  margin-right: 8px;
  font: inherit;
  color: #212325;
  width: 20px;
  height: 20px;
  border: 2px solid #212325;
  border-radius: 50%;
  transform: translateY(2.5px);
  display: grid;
  place-content: center;
}
.bux-multi-form__step-icon::before {
  content: "";
  font-family: "bux-icons";
  transform: scale(0);
}
.bux-multi-form__step-icon--current {
  border-color: #ba0c2f;
}
.bux-multi-form__step-icon--current::before {
  transform: scale(1);
  transition: 120ms transform ease-in-out;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ba0c2f;
}
.bux-multi-form__step-icon--complete::before {
  content: "\f101";
  font-size: 11px;
  transform: scale(1);
}

.bux-multi-form__form {
  display: none;
}
.bux-multi-form__form:first-child {
  display: block;
}

.bux-multi-form__button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.bux-multi-form__layout--horizontal .bux-multi-form__progress {
  height: 4px;
}
.bux-multi-form__layout--horizontal .bux-multi-form__progress-filled {
  width: 0;
}
.bux-multi-form__layout--horizontal .bux-multi-form__step-container {
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  padding: 0 16px 0 4px;
  margin-bottom: 48px;
}
.bux-multi-form__layout--horizontal .bux-multi-form__step {
  display: grid;
  grid-template-columns: 18px auto;
  gap: 8px;
  margin-top: 8px;
}

.bux-multi-form__layout--vertical {
  display: flex;
}
.bux-multi-form__layout--vertical .bux-multi-form__progress {
  width: 4px;
  align-self: stretch;
}
.bux-multi-form__layout--vertical .bux-multi-form__progress-filled {
  width: 100%;
  height: 0;
}
.bux-multi-form__layout--vertical .bux-multi-form__step-container {
  grid-template-rows: repeat(auto-fit, minmax(25%, 1fr));
  padding: 8px 32px 0 4px;
}
.bux-multi-form__layout--vertical .bux-multi-form__step {
  display: flex;
}
.bux-multi-form__layout--vertical .bux-multi-form__form-container {
  flex-grow: 1;
  padding-top: 8px;
}

.bux-radio {
  position: relative;
}
.bux-radio__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-radio__required {
  color: #ba0c2f;
}
.bux-radio__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-radio__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-radio__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-radio__legend + * {
  clear: both;
}
.bux-radio__input-spacer {
  margin-top: 8px;
}
.bux-radio__option {
  display: grid;
  grid-template-columns: 18px auto;
  gap: 8px;
}
.bux-radio__option label {
  margin-top: 2px;
}
.bux-radio__option + .bux-radio__option {
  margin-top: 8px;
}
.bux-radio__option input[type=radio] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #212325;
  width: 20px;
  height: 20px;
  border: 2px solid #212325;
  border-radius: 50%;
  transform: translateY(2.5px);
  display: grid;
  place-content: center;
}
.bux-radio__option input[type=radio]:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #ba0c2f;
}
.bux-radio__option input[type=radio]:checked:before {
  transform: scale(1);
}
.bux-radio__option input[type=radio]:checked {
  border-color: #ba0c2f;
}
.bux-radio__option input[type=radio]:focus {
  outline: 2px solid #3492b8 !important;
}
.bux-radio__option--disabled input[type=radio] {
  border: 2px solid #cfd4d8;
  background-color: #eff1f2;
}
.bux-radio__option--disabled label {
  color: #bfc6cb;
}
.bux-radio__option--error > input[type=radio] {
  background: #ffeff2;
  border-color: #ba0c2f;
}
.bux-radio__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
  margin-top: 5px;
}
.bux-radio__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}

.bux-selection-dropdown {
  position: relative;
}
.bux-selection-dropdown__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-selection-dropdown__required {
  color: #ba0c2f;
}
.bux-selection-dropdown__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-selection-dropdown__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-selection-dropdown__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-selection-dropdown__legend + * {
  clear: both;
}
.bux-selection-dropdown__input-spacer {
  margin-top: 8px;
}
.bux-selection-dropdown__input {
  position: relative;
  margin-top: 8px;
}
.bux-selection-dropdown__input select {
  appearance: none;
  background: #fff;
  border: 2px solid #212325;
  border-radius: 0;
  width: 100%;
  height: 44px;
  padding-left: 8px;
}
.bux-selection-dropdown__input select:focus {
  outline: 2px solid #3492b8;
}
.bux-selection-dropdown__input select:focus:not(:focus-visible) {
  outline: none !important;
}
.bux-selection-dropdown__input select option {
  background-color: #fff;
  padding: 15px 0;
  border: 2px solid #212325;
}
.bux-selection-dropdown__input:before {
  content: "\f010";
  font-size: 22px;
  font-family: "bux-icons";
  background-color: #fff;
  color: #212325;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  pointer-events: none;
}
.bux-selection-dropdown__input--disabled select {
  color: #cfd4d8;
  border-color: #cfd4d8;
  background-color: #eff1f2;
  cursor: not-allowed;
  opacity: 1;
}
.bux-selection-dropdown__input--disabled:before {
  background-color: #eff1f2;
  color: #cfd4d8;
}
.bux-selection-dropdown__input--error > select {
  background: #ffeff2;
  border-color: #ba0c2f;
}
.bux-selection-dropdown__input--error:before {
  background: #ffeff2;
}
.bux-selection-dropdown__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
  margin-top: 8px;
}
.bux-selection-dropdown__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}

.bux-switch {
  position: relative;
}
.bux-switch__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-switch__required {
  color: #ba0c2f;
}
.bux-switch__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-switch__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-switch__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-switch__legend + * {
  clear: both;
}
.bux-switch__input-spacer {
  margin-top: 8px;
}
.bux-switch__option {
  display: grid;
  grid-template-columns: 45px auto;
  gap: 12px;
}
.bux-switch__option label {
  margin-top: 4px;
}
.bux-switch__option + .bux-switch__option {
  margin-top: 8px;
}
.bux-switch__option input {
  appearance: none;
  cursor: pointer;
  background-color: #212325;
  border: none;
  border-radius: 32px;
  height: 24px;
  width: 45px;
  display: grid;
  place-content: center;
  margin: 0;
  transform: translateY(3px);
}
.bux-switch__option input:checked {
  background-color: #ba0c2f;
}
.bux-switch__option input:before {
  content: "";
  height: 18px;
  width: 18px;
  background-color: white;
  transition: 120ms transform ease-in-out;
  border-radius: 50%;
  transform: translate(-10px, 4px);
}
.bux-switch__option input:checked:before {
  transform: translate(10px, 4px);
}
.bux-switch__option input:after {
  content: "";
  height: 9px;
  width: 2px;
  border-radius: 2px;
  background-color: #fff;
  transition: 120ms transform ease-in-out;
  transform: translate(0, -6px);
}
.bux-switch__option input:checked:after {
  background-color: #ba0c2f;
  transform: translate(18.5px, -9px);
}
.bux-switch__option input:focus {
  outline: 2px solid #3492b8;
}
.bux-switch__option--disabled input {
  background-color: #cfd4d8;
}
.bux-switch__option--disabled label {
  color: #cfd4d8;
}
.bux-switch__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
  margin-top: 8px;
}
.bux-switch__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}

.bux-text-area {
  position: relative;
}
.bux-text-area__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-text-area__required {
  color: #ba0c2f;
}
.bux-text-area__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-text-area__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-text-area__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-text-area__legend + * {
  clear: both;
}
.bux-text-area__input-spacer {
  margin-top: 8px;
}
.bux-text-area__text-area {
  display: block;
  background-color: #fff;
  border: 2px solid #eff1f2;
  border-bottom: 2px solid #212325;
  padding: 8px;
  margin: 8px 0;
  width: 100%;
}
.bux-text-area__text-area::placeholder {
  color: #646a6e;
  opacity: 1;
  font-size: 1rem;
}
.bux-text-area__text-area:focus {
  border-color: #212325;
}
.bux-text-area__text-area:focus {
  outline: 2px solid #3492b8;
}
.bux-text-area__text-area--disabled {
  border-color: #cfd4d8;
  background-color: #eff1f2;
  cursor: not-allowed;
}
.bux-text-area__text-area--disabled::placeholder {
  color: #646a6e;
}
.bux-text-area__text-area--disabled:focus {
  outline: none;
  border-color: #cfd4d8;
}
.bux-text-area__text-area--error {
  border-color: #ba0c2f;
  background: #ffeff2;
}
.bux-text-area__text-area--error:focus {
  border-color: #ba0c2f;
}
.bux-text-area__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
}
.bux-text-area__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}

.bux-text-field {
  position: relative;
}
.bux-text-field__label {
  display: block;
  font-weight: 700;
  font-size: 1rem;
}
.bux-text-field__required {
  color: #ba0c2f;
}
.bux-text-field__helper-text {
  display: block;
  font-size: 0.875rem;
  color: #212325;
  margin-top: 8px;
}
.bux-text-field__fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bux-text-field__legend {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
}
.bux-text-field__legend + * {
  clear: both;
}
.bux-text-field__input-spacer {
  margin-top: 8px;
}
.bux-text-field__input {
  display: block;
  background-color: #fff;
  border: 2px solid #eff1f2;
  border-bottom: 2px solid #212325;
  padding: 8px;
  margin: 8px 0;
  width: 100%;
  height: 40px;
}
.bux-text-field__input::placeholder {
  color: #646a6e;
  opacity: 1;
  font-size: 1rem;
}
.bux-text-field__input:focus {
  border-color: #212325;
}
.bux-text-field__input:focus {
  outline: 2px solid #3492b8;
}
.bux-text-field__input--disabled {
  border-color: #cfd4d8;
  background-color: #eff1f2;
  cursor: not-allowed;
}
.bux-text-field__input--disabled::placeholder {
  color: #646a6e;
}
.bux-text-field__input--disabled:focus {
  outline: none;
  border-color: #cfd4d8;
}
.bux-text-field__input--error {
  border-color: #ba0c2f;
  background: #ffeff2;
}
.bux-text-field__input--error:focus {
  border-color: #ba0c2f;
}
.bux-text-field__input--password {
  display: inline-block;
}
.bux-text-field__error-message {
  display: block;
  font-size: 0.875rem;
  padding-left: 20px;
  font-size: 0.875rem;
  color: #ba0c2f;
}
.bux-text-field__error-message:before {
  content: "\f106";
  font-family: "bux-icons";
  color: #ba0c2f;
  position: absolute;
  left: 0;
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 1.3;
}
.bux-text-field__password-icon {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  height: 30px;
  width: 36px;
  font-size: 18px;
  position: absolute;
  right: 2px;
  margin-top: 18px;
}
.bux-text-field__password-icon:focus {
  outline: 2px solid #3492b8;
}
.bux-text-field__password-icon--view:after {
  content: "\f014";
  font-family: "bux-icons";
}
.bux-text-field__password-icon--hide:after {
  content: "\f100";
  font-family: "bux-icons";
}

.bux-header,
.bux-header--white {
  background-color: #fff;
  color: #212325;
  padding: 32px 0;
}
.bux-header .bux-header__site-name-parent,
.bux-header .bux-header__site-name-slogan,
.bux-header--white .bux-header__site-name-parent,
.bux-header--white .bux-header__site-name-slogan {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}
@media (min-width: 640px) {
  .bux-header .bux-header__site-name-parent,
  .bux-header .bux-header__site-name-slogan,
  .bux-header--white .bux-header__site-name-parent,
  .bux-header--white .bux-header__site-name-slogan {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}
.bux-header .bux-header__site-name-parent,
.bux-header--white .bux-header__site-name-parent {
  padding-bottom: 4px;
}
.bux-header .bux-header__site-name-slogan,
.bux-header--white .bux-header__site-name-slogan {
  padding-top: 4px;
}
.bux-header .bux-header__site-name, .bux-header a,
.bux-header--white .bux-header__site-name,
.bux-header--white a {
  color: #212325;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.875rem;
  text-decoration: none;
  margin-top: 0;
}
@media (min-width: 640px) {
  .bux-header .bux-header__site-name, .bux-header a,
  .bux-header--white .bux-header__site-name,
  .bux-header--white a {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.bux-header--dark {
  background-color: #212325;
  color: #fff;
  padding: 32px 0;
}
.bux-header--dark .bux-header__site-name-parent,
.bux-header--dark .bux-header__site-name-slogan {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}
@media (min-width: 640px) {
  .bux-header--dark .bux-header__site-name-parent,
  .bux-header--dark .bux-header__site-name-slogan {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}
.bux-header--dark .bux-header__site-name-parent {
  padding-bottom: 4px;
}
.bux-header--dark .bux-header__site-name-slogan {
  padding-top: 4px;
}
.bux-header--dark .bux-header__site-name, .bux-header--dark a {
  color: #fff;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.875rem;
  text-decoration: none;
  margin-top: 0;
}
@media (min-width: 640px) {
  .bux-header--dark .bux-header__site-name, .bux-header--dark a {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 0) {
  h1,
  .bux-h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 900;
    font-family: "BuckeyeSerif", "Georgia", serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h1 a,
  .bux-h1 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h1 a:hover,
  .bux-h1 a:hover {
    text-decoration: underline;
  }
  h2,
  .bux-h2,
  *.bux-panel__heading {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 800;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h2 a,
  .bux-h2 a,
  *.bux-panel__heading a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h2 a:hover,
  .bux-h2 a:hover,
  *.bux-panel__heading a:hover {
    text-decoration: underline;
  }
  h3,
  .bux-h3 {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h3 a,
  .bux-h3 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h3 a:hover,
  .bux-h3 a:hover {
    text-decoration: underline;
  }
  h4,
  .bux-h4 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h4 a,
  .bux-h4 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h4 a:hover,
  .bux-h4 a:hover {
    text-decoration: underline;
  }
  h5,
  .bux-h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h5 a,
  .bux-h5 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h5 a:hover,
  .bux-h5 a:hover {
    text-decoration: underline;
  }
  h6,
  .bux-h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h6 a,
  .bux-h6 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h6 a:hover,
  .bux-h6 a:hover {
    text-decoration: underline;
  }
  h1.bux-h1--alt {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 800;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h1.bux-h1--alt a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h1.bux-h1--alt a:hover {
    text-decoration: underline;
  }
  h2.bux-h2--alt {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h2.bux-h2--alt a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h2.bux-h2--alt a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 640px) {
  h1,
  .bux-h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 900;
    font-family: "BuckeyeSerif", "Georgia", serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h1 a,
  .bux-h1 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h1 a:hover,
  .bux-h1 a:hover {
    text-decoration: underline;
  }
  h2,
  .bux-h2,
  *.bux-panel__heading {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 800;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h2 a,
  .bux-h2 a,
  *.bux-panel__heading a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h2 a:hover,
  .bux-h2 a:hover,
  *.bux-panel__heading a:hover {
    text-decoration: underline;
  }
  h3,
  .bux-h3 {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 600;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h3 a,
  .bux-h3 a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h3 a:hover,
  .bux-h3 a:hover {
    text-decoration: underline;
  }
  h1.bux-h1--alt {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 800;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    color: #212325;
    margin: 0;
    margin-bottom: 8px;
  }
  h1.bux-h1--alt a {
    text-decoration: none;
    color: #ba0c2f;
  }
  h1.bux-h1--alt a:hover {
    text-decoration: underline;
  }
}
.bux-hero {
  position: relative;
  display: flex;
}
.bux-hero .bux-hero__content {
  background-color: #f6f7f8;
  padding: 24px;
}
@media (min-width: 960px) {
  .bux-hero .bux-hero__content {
    padding: 32px 32px 32px 64px;
  }
}
.bux-hero .bux-hero__header {
  display: block;
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 900;
  color: #212325;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero .bux-hero__header {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}
.bux-hero .bux-hero__header span {
  display: block;
}
.bux-hero .bux-hero__subheader {
  display: block;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 600;
  margin-bottom: 24px;
  color: #646a6e;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero .bux-hero__subheader {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.bux-hero .bux-hero__subheader span {
  display: block;
}
.bux-hero__carousel-items {
  margin: 0;
  width: 100%;
}
@media (min-width: 960px) {
  .bux-hero__carousel-items {
    padding: 0 15px 0;
  }
}
.bux-hero__content {
  width: 100%;
  order: 1;
}
.bux-hero__container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  transition: 1s;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: none;
}
.bux-hero__image {
  order: 0;
  height: 0;
  position: relative;
  overflow: hidden;
}
.bux-hero__image img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transform: none;
  width: 100%;
}
.bux-hero__image--3x2 img {
  aspect-ratio: 3/2;
}
.bux-hero__image--16x9 img {
  aspect-ratio: 16/9;
}
.bux-hero__button {
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  background-color: #eff1f2;
  border: solid 2px #dfe3e5;
  border-radius: 0;
  color: #3f4443;
  font-size: 1.75rem;
  cursor: pointer;
  z-index: 99;
}
.bux-hero__button:hover {
  background-color: #3f4443;
  color: #fff;
  border-color: #3f4443;
}
.bux-hero__button:focus {
  outline: 2px solid #3492b8;
  outline-offset: -2px;
}
.bux-hero__button .icon {
  display: block;
  padding-top: 0.0625rem;
}
.bux-hero__button--left {
  left: 0;
}
.bux-hero__button--right {
  right: 0;
}
.bux-hero__tabs {
  display: none;
}
@media (min-width: 960px) {
  .bux-hero__tabs {
    align-items: center;
    bottom: 16px;
    display: flex;
    justify-content: center;
    left: auto;
    position: absolute;
    right: auto;
    width: 100%;
    z-index: 99;
  }
}
.bux-hero__tab {
  height: 1.25rem;
  width: 1.25rem;
  background: #fff;
  border: 2px solid #646a6e;
  border-radius: 100%;
  margin-left: 10px;
  cursor: pointer;
}
.bux-hero__tab:first-child {
  margin-left: 0;
}
.bux-hero__tab:hover {
  background: #646a6e;
  border: none;
}
.bux-hero__tab--active {
  background: #3f4443;
  border: 2px solid #fff;
  height: 1.375rem;
  width: 1.375rem;
}

.showing {
  opacity: 1;
  z-index: 2;
  display: flex;
}

.bux-hero--card-right .showing img {
  animation: fadeInLeft 1s;
}
.bux-hero--card-right .showing .bux-hero__header span {
  animation: slideUp 1s;
}
.bux-hero--card-right .showing .bux-hero__subheader span {
  animation: slideUp 1s;
}

.bux-hero--card-left .showing img {
  animation: fadeInRight 1s;
}
.bux-hero--card-left .showing .bux-hero__header span {
  animation: slideUp 1s;
}
.bux-hero--card-left .showing .bux-hero__subheader span {
  animation: slideUp 1s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    left: -40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.bux-hero-container {
  position: relative;
}

.bux-hero--scarlet .bux-hero__content {
  background-color: #ba0c2f;
  padding: 24px;
}
@media (min-width: 960px) {
  .bux-hero--scarlet .bux-hero__content {
    padding: 32px 32px 32px 64px;
  }
}
.bux-hero--scarlet .bux-hero__header {
  display: block;
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 900;
  color: #fff;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero--scarlet .bux-hero__header {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}
.bux-hero--scarlet .bux-hero__header span {
  display: block;
}
.bux-hero--scarlet .bux-hero__subheader {
  display: block;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 600;
  margin-bottom: 24px;
  color: #fff;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero--scarlet .bux-hero__subheader {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.bux-hero--scarlet .bux-hero__subheader span {
  display: block;
}

.bux-hero--white .bux-hero__content {
  background-color: #fff;
  padding: 24px;
}
@media (min-width: 960px) {
  .bux-hero--white .bux-hero__content {
    padding: 32px 32px 32px 64px;
  }
}
.bux-hero--white .bux-hero__header {
  display: block;
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 900;
  color: #212325;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero--white .bux-hero__header {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}
.bux-hero--white .bux-hero__header span {
  display: block;
}
.bux-hero--white .bux-hero__subheader {
  display: block;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 600;
  margin-bottom: 24px;
  color: #646a6e;
  overflow: hidden;
}
@media (min-width: 960px) {
  .bux-hero--white .bux-hero__subheader {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.bux-hero--white .bux-hero__subheader span {
  display: block;
}

@media (min-width: 960px) {
  .bux-hero {
    flex-direction: row;
    align-items: stretch;
  }
  .bux-hero__container {
    flex-direction: row;
    overflow-y: hidden;
  }
  .bux-hero__content {
    order: 0;
    flex-shrink: 2;
  }
  .bux-hero__image {
    order: 1;
    padding-top: 100%;
  }
  .bux-hero__image img {
    aspect-ratio: auto;
    height: 100%;
    width: 100%;
  }
}
.bux-hero--card-right .bux-hero__content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
@media (min-width: 960px) {
  .bux-hero--card-right.bux-hero--3x2 .bux-hero__content {
    padding: 32px 64px 32px 32px;
  }
}
@media (min-width: 960px) {
  .bux-hero--card-right.bux-hero--16x9 .bux-hero__content {
    padding: 16px 64px 16px 16px;
  }
}
.bux-hero--card-left .bux-hero__content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
@media (min-width: 960px) {
  .bux-hero--card-left.bux-hero--3x2 .bux-hero__content {
    padding: 32px 32px 32px 64px;
  }
}
@media (min-width: 960px) {
  .bux-hero--card-left.bux-hero--16x9 .bux-hero__content {
    padding: 16px 16px 16px 64px;
  }
}
.bux-hero--card-full .bux-hero__content {
  max-width: 800px;
  padding: 32px;
  text-align: center;
}
.bux-hero--card-full .bux-text-link {
  cursor: pointer;
  display: inline;
}
.bux-hero--card-full .bux-text-link::after {
  display: inline;
  font-size: 1.25rem;
  font-weight: 400;
  position: absolute;
  top: auto;
}
.bux-hero--card-full .bux-hero__header {
  font-size: 1.75rem;
  line-height: 2.125rem;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__header {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}
.bux-hero--card-full .bux-hero__subheader {
  margin-bottom: 0;
}
.bux-hero--3x2 .bux-hero__image {
  padding-top: 66.66%;
}
@media (min-width: 960px) {
  .bux-hero--3x2 .bux-hero__content {
    width: 40%;
  }
  .bux-hero--3x2 .bux-hero__image-container {
    width: 60%;
  }
}
.bux-hero--16x9 .bux-hero__image {
  padding-top: 56.25%;
}
@media (min-width: 960px) {
  .bux-hero--16x9 .bux-hero__content {
    width: 40%;
  }
  .bux-hero--16x9 .bux-hero__image-container {
    width: 60%;
  }
}
.bux-hero--card-full .bux-hero__container {
  align-items: center;
  flex-direction: column;
}
.bux-hero--card-full .bux-hero__content {
  z-index: 2;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__content {
    margin-top: -70px;
    order: 1;
    width: auto;
  }
}
.bux-hero--card-full .bux-hero__image-container {
  width: 100%;
}
.bux-hero--card-full .bux-hero__image {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__image {
    order: 0;
  }
}
.bux-hero--card-full img {
  aspect-ratio: 16/9;
  left: 0;
  position: absolute;
  top: 0;
}
.bux-hero--card-full video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bux-hero--card-full .bux-hero__video-button {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.bux-hero--card-full .bux-hero__video-button:focus {
  outline: 2px solid #3492b8;
  outline-offset: -2px;
}
.bux-hero--card-full .bux-hero__video-button-icon {
  background: #fff;
  border: 2px solid white;
  border-radius: 50%;
  color: #ba0c2f;
  font-family: bux-icons !important;
  font-size: 1.9375rem;
  font-weight: 800;
  height: 50px;
  left: 0;
  line-height: 2.5rem;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__video-button-icon {
    font-size: 3.875rem;
    height: 100px;
    line-height: 5rem;
    width: 100px;
  }
}
.bux-hero--card-full .bux-hero__video-button-icon--play {
  visibility: hidden;
}
.bux-hero--card-full .bux-hero__video-button-icon--play .bux-icon {
  display: block;
  margin: 2px 0 0 4px;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__video-button-icon--play .bux-icon {
    margin: 8px 0 0 8px;
  }
}
.bux-hero--card-full .bux-hero__video-button-icon--pause {
  visibility: hidden;
}
.bux-hero--card-full .bux-hero__video-button-icon--pause .bux-icon {
  display: block;
  margin: 4px 0 0 0;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__video-button-icon--pause .bux-icon {
    margin: 8px 0 0 0;
  }
}
.bux-hero--card-full .bux-hero__video-button:hover .bux-hero__video-button-icon--play, .bux-hero--card-full .bux-hero__video-button:focus .bux-hero__video-button-icon--play {
  visibility: hidden;
}
.bux-hero--card-full .bux-hero__video-button:hover .bux-hero__video-button-icon--pause, .bux-hero--card-full .bux-hero__video-button:focus .bux-hero__video-button-icon--pause {
  line-height: 2.1875rem;
  visibility: visible;
}
@media (min-width: 960px) {
  .bux-hero--card-full .bux-hero__video-button:hover .bux-hero__video-button-icon--pause, .bux-hero--card-full .bux-hero__video-button:focus .bux-hero__video-button-icon--pause {
    line-height: 4.5625rem;
  }
}
.bux-hero--card-full .bux-hero__video-play:hover .bux-hero__video-button-icon--play, .bux-hero--card-full .bux-hero__video-play:focus .bux-hero__video-button-icon--play {
  visibility: visible;
}
.bux-hero--card-full .bux-hero__video-play:hover .bux-hero__video-button-icon--pause, .bux-hero--card-full .bux-hero__video-play:focus .bux-hero__video-button-icon--pause {
  visibility: hidden;
}

@media (min-width: 960px) {
  .bux-hero--card-right .bux-hero__image {
    order: 1;
  }
  .bux-hero--card-right .bux-hero__content {
    order: 2;
  }
}
hr,
.bux-horizontal-rule {
  border: 0;
  border-top: 2px solid #eff1f2;
}

.bux-image {
  display: block;
  width: 100%;
}
.bux-image__float-left {
  float: left;
  margin-right: 32px;
}
.bux-image__float-right {
  float: right;
  margin-left: 32px;
}
.bux-image--16x9 {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
}
.bux-image--1x1 {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
}
.bux-image--3x2 {
  aspect-ratio: 3/2;
  width: 100%;
  object-fit: cover;
}

.bux-image-caption {
  border-bottom: 2px solid #eff1f2;
  margin-bottom: 32px;
}

.bux-image-caption__text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 10px;
}

.bux-link-list {
  font-size: 1rem;
}
.bux-link-list__item {
  border-top: 2px solid #eff1f2;
  padding: 16px;
}
.bux-link-list__item:only-child {
  border-bottom: 2px solid #eff1f2;
}
.bux-link-list__text {
  margin-top: 8px;
}
.bux-link-list .bux-text-link {
  display: block;
  font-weight: 700;
  margin-right: 0;
  padding-right: 24px;
  position: relative;
}
.bux-link-list .bux-text-link:after {
  font-weight: 400;
  padding-left: 8px;
  right: 8px;
}
.bux-link-list .bux-text-link:hover:after {
  right: 0;
}

.bux-link {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-link:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-link:hover {
  color: #212325;
  border-bottom-color: #212325;
  background-color: #eff1f2;
}
.bux-link:focus {
  color: #ba0c2f;
}
.bux-link:visited {
  color: #660099;
  border-bottom-color: #660099;
}
.bux-link--before:before {
  content: "\f015";
  font-size: 0.875rem;
  font-family: "bux-icons";
  padding-right: 4px;
}
.bux-link--after:after {
  content: "\f116";
  font-size: 0.875rem;
  font-family: "bux-icons";
  padding-left: 4px;
}

.bux-link--reverse {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #fff;
  border-bottom-color: #fff;
}
.bux-link--reverse:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-link--reverse:hover {
  color: #fff;
  border-bottom-color: #fff;
  background-color: #646a6e;
}
.bux-link--reverse:focus {
  color: #fff;
  background-color: #646a6e;
}
.bux-link--reverse:visited {
  color: #e7cdf4;
  border-bottom-color: #e7cdf4;
}
.bux-link--reverse:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-link--reverse:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}

.bux-link--alt {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-link--alt:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-link--alt:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-link--alt:focus {
  color: #ba0c2f;
  background-color: #fff;
}
.bux-link--alt:visited {
  color: #660099;
  border-bottom-color: #660099;
}

.bux-link--scarlet {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #fff;
  border-bottom-color: #fff;
}
.bux-link--scarlet:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-link--scarlet:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-link--scarlet:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}
.bux-link--scarlet:visited {
  color: #e7cdf4;
  border-bottom-color: #e7cdf4;
}
.bux-link--scarlet:hover {
  color: #212325;
  border-bottom-color: #fff;
  background-color: #eff1f2;
}
.bux-link--scarlet:focus {
  color: #212325;
  background-color: #eff1f2;
  outline: 2px solid #74BAD8;
}

dl.bux-dl {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0;
}

dt {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
}

dd {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  margin-bottom: 16px;
}

ol.bux-list-ol {
  list-style: none;
  counter-reset: bux-ol;
  margin-left: 0;
  margin-top: 0;
  padding-left: calc(64px - 16px);
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1rem;
  line-height: 1.5;
}
ol.bux-list-ol li {
  counter-increment: bux-ol;
  margin-bottom: 8px;
  margin-left: 16px;
}
ol.bux-list-ol li::before {
  content: counter(bux-ol) ".";
  display: inline-block;
  width: 24px;
  margin-left: -24px;
}

ol.bux-list-ol ol {
  list-style: none;
  counter-reset: bux-ol;
  padding-left: 32px;
  margin-top: 8px;
}
ol.bux-list-ol ol li {
  counter-increment: bux-ol;
  margin-bottom: 8px;
}
ol.bux-list-ol ol li::before {
  content: counter(bux-ol, lower-alpha) ".";
  padding-right: 16px;
}

ol.bux-list-ol ol ol {
  list-style: none;
  counter-reset: bux-ol;
  padding-left: 32px;
  margin-top: 8px;
}
ol.bux-list-ol ol ol li {
  counter-increment: bux-ol;
  margin-bottom: 8px;
}
ol.bux-list-ol ol ol li::before {
  content: counter(bux-ol, lower-roman) ".";
  padding-right: 16px;
}

ul.bux-list-ul {
  list-style: none;
  margin-left: 0;
  margin-top: 0;
  padding-left: calc(64px - 16px);
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1rem;
  line-height: 1.5;
}
ul.bux-list-ul li {
  list-style-type: none;
  margin-bottom: 8px;
  padding-left: 24px;
}
ul.bux-list-ul li::before {
  color: #ba0c2f;
  content: "\f001";
  font-family: "bux-icons";
  font-size: 0.4375rem;
  display: inline-block;
  width: 32px;
  vertical-align: middle;
  margin-left: -32px;
}

ul.bux-list-ul ul {
  padding-left: 24px;
  margin-top: 8px;
}
ul.bux-list-ul ul li::before {
  content: "\f002";
}

ul.bux-list-ul ul ul {
  padding-left: 24px;
}
ul.bux-list-ul ul ul li::before {
  content: "\f003";
}

.bux-listicle__item {
  margin-bottom: 32px;
}
.bux-listicle__item:last-child {
  margin-bottom: 0;
}

.bux-listicle__title {
  display: flex;
}

.bux-listicle__title-number {
  background: #dfe3e5;
  color: #212325;
  height: 32px;
  min-width: 32px;
  margin-right: 16px;
  font-size: 1.25rem;
  line-height: 1.675;
  text-align: center;
  font-weight: 700;
  display: block;
}

.bux-listicle__title-text {
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  color: #212325;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 3px;
  display: block;
}
.bux-listicle__title-text a {
  text-decoration: none;
  color: #ba0c2f;
}
.bux-listicle__title-text a:hover {
  text-decoration: underline;
}

.bux-listicle__text {
  position: relative;
  padding-left: calc(32px + 16px);
}

.bux-listicle__item--unordered .bux-listicle__title {
  margin-bottom: 0;
}
.bux-listicle__item--unordered .bux-listicle__title-text {
  margin-top: 0;
  margin-bottom: 16px;
}
.bux-listicle__item--unordered .bux-listicle__title-number {
  display: none;
}
.bux-listicle__item--unordered .bux-listicle__text {
  left: 0;
  padding-left: 0;
}

div.bux-container--menu {
  position: relative;
  z-index: 999;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-closed {
  display: none;
}
div.bux-container--menu nav.disclosure-nav button.disclosure-nav-button {
  background-color: transparent;
  border: none;
}
div.bux-container--menu nav.disclosure-nav button.disclosure-nav-button::after {
  font-family: bux-icons;
  content: "\f010";
  color: #212325;
  position: relative;
  top: -1px;
  left: 4px;
}
div.bux-container--menu nav.disclosure-nav button.disclosure-nav-button[aria-expanded=true]::after {
  content: "\f011";
}
div.bux-container--menu nav.disclosure-nav button.disclosure-nav-button[aria-expanded=false]::after {
  content: "\f010";
}
div.bux-container--menu nav.disclosure-nav a, div.bux-container--menu nav.disclosure-nav span {
  color: #212325;
  text-decoration: none;
}
div.bux-container--menu nav.disclosure-nav ul, div.bux-container--menu nav.disclosure-nav ol {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
div.bux-container--menu nav.disclosure-nav ul.disclosure-nav-submenu-open, div.bux-container--menu nav.disclosure-nav ol.disclosure-nav-submenu-open {
  display: block;
}
div.bux-container--menu nav.disclosure-nav ul.disclosure-nav-submenu-closed, div.bux-container--menu nav.disclosure-nav ol.disclosure-nav-submenu-closed {
  display: none;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li {
  display: inline-flex;
  position: relative;
  margin: 12px 16px 0;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover a.bux-menu__link,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover span.bux-menu__link,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover button.disclosure-nav-button-no-link {
  border-bottom-color: #646a6e;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover a.bux-menu__link.bux-menu__link--active,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover span.bux-menu__link.bux-menu__link--active,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover button.disclosure-nav-button-no-link.bux-menu__link--active {
  border-bottom-color: #646a6e;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover a.bux-menu__link.bux-menu__link--active + button,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover span.bux-menu__link.bux-menu__link--active + button,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover button.disclosure-nav-button-no-link.bux-menu__link--active + button {
  border-bottom-color: #646a6e;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover a.bux-menu__link + button,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover span.bux-menu__link + button,
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li:hover button.disclosure-nav-button-no-link + button {
  border-bottom-color: #646a6e;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > a.bux-menu__link, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > span.bux-menu__link {
  float: inline-block;
  padding: 0 0 4px 0;
  color: #212325;
  text-decoration: none;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  white-space: nowrap;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > a.bux-menu__link.bux-menu__link--active, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > span.bux-menu__link.bux-menu__link--active {
  border-bottom-color: #ba0c2f;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > a.bux-menu__link.bux-menu__link--active + button, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > span.bux-menu__link.bux-menu__link--active + button {
  border-bottom-color: #ba0c2f;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li > button {
  border-bottom: 4px solid rgba(0, 0, 0, 0);
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li:first-child {
  border-top: 2px solid #eff1f2;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li:last-child {
  border-bottom: 2px solid #eff1f2;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li a, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li span, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li button.disclosure-nav-button-no-link {
  display: block;
  padding: 12px 64px 12px 12px;
  background-color: #fff;
  white-space: normal;
  border-bottom: none;
  border-left: 4px solid rgba(0, 0, 0, 0);
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li a:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li a:focus, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li span:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li span:focus, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li button.disclosure-nav-button-no-link:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li button.disclosure-nav-button-no-link:focus {
  border-left-color: #3f4443;
  background-color: #f6f7f8;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li a.bux-menu__link--active, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li span.bux-menu__link--active, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li li button.disclosure-nav-button-no-link.bux-menu__link--active {
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #ba0c2f;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul {
  position: absolute;
  left: 0;
  top: 2rem;
  width: 15rem;
  padding: 8px 0 8px 0;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul ul {
  top: 0;
  left: 100%;
  padding: 0;
  margin-top: -2px;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul li {
  position: relative;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul button {
  float: right;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul button.disclosure-nav-button-no-link {
  float: none;
  text-align: left;
  width: 100%;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul button::after {
  position: absolute;
  top: 25%;
  right: 0;
  left: auto;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul button[aria-expanded=true]::after {
  content: "\f111";
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-horizontal > ul > li ul button[aria-expanded=false]::after {
  content: "\f111";
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical {
  background-color: #fff;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul {
  background-color: #fff;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul li {
  position: relative;
  margin: 12px 16px 0 16px;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul a, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul span, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link {
  display: block;
  border-bottom: none;
  white-space: normal;
  border-left: 4px solid rgba(0, 0, 0, 0);
  padding: 12px;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul a.disclosure-nav-item-with-submenu, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul span.disclosure-nav-item-with-submenu, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link.disclosure-nav-item-with-submenu {
  padding-right: 2.45rem;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul a:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul a:focus, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul span:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul span:focus, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:hover, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:focus {
  color: #212325;
  border-left-color: #3f4443;
  background-color: #f6f7f8;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul a.bux-menu__link--active, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul span.bux-menu__link--active, div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link.bux-menu__link--active {
  border-left-color: #ba0c2f;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button {
  position: absolute;
  right: 0.45rem;
  top: 0.45rem;
  padding: 0;
  height: 2rem;
  width: 2rem;
  background-color: #f6f7f8;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button.disclosure-nav-button-no-link {
  position: static;
  right: auto;
  top: auto;
  float: none;
  text-align: left;
  width: 100%;
  height: auto;
  background-color: #fff;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button.disclosure-nav-button-no-link::after {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  left: auto;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button.disclosure-nav-button-no-link:hover::after {
  border-right-color: #212325;
  border-bottom-color: #212325;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button:hover {
  background-color: #3f4443;
  cursor: pointer;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button::after {
  content: "\f005";
  font-family: "bux-icons";
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  top: 2px;
  left: 7px;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button:hover::after {
  color: #fff;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button[aria-expanded=true]::after {
  transform: rotate(90deg);
  transition: 120ms transform ease-in-out;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button[aria-expanded=false]::after {
  transform: rotate(0deg);
  transition: 120ms transform ease-in-out;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-orientation-vertical ul ul li {
  margin-top: 0;
}
div.bux-container--menu nav.disclosure-nav.disclosure-nav-responsive {
  width: 100%;
}
div.bux-container--menu button.disclosure-nav-toggle {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #f6f7f8;
  color: #212325;
  width: 100%;
  padding: 0.5rem 50px 0.5rem 0.5rem;
  text-align: right;
  position: relative;
  touch-action: manipulation;
  font-weight: 700;
  height: 46px;
}
div.bux-container--menu button.disclosure-nav-toggle:focus, div.bux-container--menu button.disclosure-nav-toggle:hover {
  background-color: #f6f7f8;
  color: #212325;
  outline-color: #212325;
}
div.bux-container--menu button.disclosure-nav-toggle:focus span, div.bux-container--menu button.disclosure-nav-toggle:focus span::before, div.bux-container--menu button.disclosure-nav-toggle:focus span::after, div.bux-container--menu button.disclosure-nav-toggle:hover span, div.bux-container--menu button.disclosure-nav-toggle:hover span::before, div.bux-container--menu button.disclosure-nav-toggle:hover span::after {
  background: #212325;
}
div.bux-container--menu button.disclosure-nav-toggle[aria-expanded=false]::after {
  content: "\f00c";
  font-family: bux-icons;
  position: absolute;
  margin-left: 8px;
  font-size: 14px;
  top: 16px;
  font-weight: 400;
  text-rendering: geometricprecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.11);
}
div.bux-container--menu button.disclosure-nav-toggle[aria-expanded=true]::after {
  content: "\f105";
  font-family: bux-icons;
  position: absolute;
  margin-left: 8px;
  font-size: 12px;
  top: 17px;
}
div.bux-container--menu .bux-menu__link:focus, div.bux-container--menu .disclosure-nav-button:focus, div.bux-container--menu button.disclosure-nav-toggle:focus, div.bux-container--menu button.disclosure-nav-button-no-link:focus {
  outline: 2px solid #3492b8;
  outline-offset: -2px;
}
div.bux-container--menu button.disclosure-nav-toggle:focus {
  outline-offset: -2px;
}

.bux-container--menu .disclosure-nav-closed .bux-menu__search {
  display: none;
}
.bux-container--menu .disclosure-nav-open .bux-menu__search {
  display: flex;
  margin-top: 16px;
  width: 100%;
  padding: 0 16px;
}
.bux-container--menu .disclosure-nav-open .bux-menu__search .bux-form__text-field--menu-search__wrapper {
  width: 100%;
}
.bux-container--menu .disclosure-nav-open .bux-menu__search #menuSearchBtn {
  display: none;
}
.bux-container--menu .disclosure-nav-open .bux-menu__search .bux-from__text-field--menu-search,
.bux-container--menu .disclosure-nav-open .bux-menu__search .bux-search__submit {
  display: inline;
}
.bux-container--menu .disclosure-nav-open .bux-menu__search .bux-search__submit {
  margin-top: 2px;
}
.bux-container--menu .bux-search__submit {
  margin-top: 6px;
}
.bux-container--menu .bux-search__submit::after {
  color: #ba0c2f;
}
.bux-container--menu #searchIcon {
  font-size: 1.25rem;
}
@media (min-width: 640px) {
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile) {
    flex-grow: 2;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 64px;
    height: 46px;
  }
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile).active {
    background-color: #f6f7f8;
    width: 100%;
  }
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile).active .bux-from__text-field--menu-search,
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile).active .bux-search__submit {
    display: inline;
  }
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile) .bux-form__text-field--menu-search__wrapper {
    position: relative;
    width: 33%;
  }
  .bux-container--menu .bux-menu__search:not(.bux-menu--mobile) .bux-from__text-field--menu-search {
    margin: 0;
    height: 46px;
  }
  .bux-container--menu #menuSearchBtn {
    display: block;
    background: none;
    border: none;
    color: #ba0c2f;
    height: 43px;
    margin-top: 3px;
  }
  .bux-container--menu #menuSearchBtn i::before {
    font-size: 1.25rem;
  }
}
@media (min-width: 960px) {
  .bux-container--menu .bux-menu__search {
    padding-right: 128px;
  }
}

.bux-container.bux-grid.bux-container--menu {
  padding: 0;
  height: 46px;
}

@media (min-width: 640px) {
  .bux-container.bux-grid.bux-container--menu {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 960px) {
  .bux-container.bux-grid.bux-container--menu {
    padding-left: 112px;
    padding-right: 112px;
  }
}
@media (min-width: 640px) {
  .bux-menu--with-search ul.bux-menu {
    margin-top: 0;
  }
}
.mobile-depth-cutoff + button {
  visibility: visible;
}
@media (min-width: 960px) {
  .mobile-depth-cutoff + button {
    visibility: hidden;
  }
}

.bux-menu-wrapper {
  background-color: #f6f7f8;
}

.bux-osu-nav {
  background-color: #fff;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  padding: 10px 0;
  position: relative;
  border-bottom: 2px solid #dfe3e5;
}
.bux-osu-nav .bux-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 960px) {
  .bux-osu-nav {
    padding: 12px 0;
  }
}
.bux-osu-nav__overlay {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}
.bux-osu-nav__wrapper {
  position: relative;
  z-index: 1000;
}

.bux-osu-nav__osu-logo-link {
  display: inline-block;
}
.bux-osu-nav__osu-logo-link:focus {
  outline: 2px solid #3492b8;
}

#osu-navname-block, .bux-osu-nav__osu-logo-img {
  height: 35px;
}
@media (min-width: 960px) {
  #osu-navname-block, .bux-osu-nav__osu-logo-img {
    height: 50px;
  }
}

#osu-navlinks {
  display: none;
}
@media (min-width: 960px) {
  #osu-navlinks {
    display: block;
  }
}

#osu-navlinks-block button {
  background: none;
  display: inline-block;
  width: 44px;
  height: 44px;
  position: relative;
  right: -12px;
  z-index: 99;
  text-align: center;
  border: none;
  border-left: 2px solid #bfc6cb;
  padding-top: 10px;
  padding-left: 14px;
  z-index: 100;
}
#osu-navlinks-block button::after {
  font-family: "bux-icons";
  content: "\f00e";
  color: #212325;
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
}
@media (min-width: 960px) {
  #osu-navlinks-block button {
    display: none;
  }
}

#osu-nav-trigger[aria-expanded=true] ~ #osu-navlinks {
  display: block;
  background-color: #fff;
  padding: 3.4375rem 1.25rem 1.25rem;
  left: 0;
  width: 100%;
  margin: 0;
  top: 0;
  position: absolute;
  z-index: 99;
}
@media (min-width: 960px) {
  #osu-nav-trigger[aria-expanded=true] ~ #osu-navlinks {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin: 0;
  }
}

#osu-nav-trigger[aria-expanded=true] {
  position: relative;
  border-left: none;
  z-index: 999;
}
@media (min-width: 640px) {
  #osu-nav-trigger[aria-expanded=true] {
    margin-right: -2px;
  }
}
#osu-nav-trigger[aria-expanded=true]::after {
  content: "\f105";
}
#osu-nav-trigger[aria-expanded=true]::before {
  color: #212325;
  content: "";
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  position: absolute;
  right: 50px;
  top: 10px;
}

.bux-osu-nav__link {
  border-bottom: 2px solid #dfe3e5;
}
@media (min-width: 960px) {
  .bux-osu-nav__link {
    padding: 12px;
  }
}
.bux-osu-nav__link:first-child {
  border-top: 2px solid #dfe3e5;
}
.bux-osu-nav__link a {
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
  border-bottom: none;
  color: #3f4443;
  display: block;
  padding: 12px;
  font-weight: 700;
}
.bux-osu-nav__link a:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-osu-nav__link a:hover {
  color: #000;
  border-bottom-color: #212325;
  background-color: #fff;
}
.bux-osu-nav__link a:focus {
  color: #ba0c2f;
  background-color: #fff;
}
@media (min-width: 960px) {
  .bux-osu-nav__link a {
    display: inline;
    padding: 0;
  }
}
.bux-osu-nav__link a:hover, .bux-osu-nav__link a:focus {
  border-left: 4px solid #3f4443;
  padding-left: 8px;
  color: #ba0c2f;
}
@media (min-width: 960px) {
  .bux-osu-nav__link a:hover, .bux-osu-nav__link a:focus {
    border-left: none;
    padding-left: 0;
  }
}
@media (min-width: 960px) {
  .bux-osu-nav__link {
    display: inline-block;
    margin: 0;
    margin-left: 8px;
    border-bottom: none;
  }
  .bux-osu-nav__link:first-child {
    border-top: none;
  }
  .bux-osu-nav__link a {
    font-size: 1rem;
  }
  .bux-osu-nav__link a:hover {
    color: #ba0c2f;
    border-bottom: 1px solid #ba0c2f;
  }
}

.bux-pagination {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  margin-top: 4px;
}
.bux-pagination .bux-pagination__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bux-pagination .bux-pagination__link {
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  color: #ba0c2f;
  text-decoration: none;
  height: 40px;
  width: 40px;
  padding: 8px;
  text-align: center;
}
.bux-pagination .bux-pagination__link:hover {
  background-color: #eff1f2;
  color: #212325;
}
.bux-pagination .bux-pagination__link:focus {
  position: relative;
  outline: 2px solid #3492b8;
}
.bux-pagination .bux-pagination__link--truncated:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #ba0c2f;
}
.bux-pagination .bux-pagination__link--previous:before,
.bux-pagination .bux-pagination__link--next:after {
  font-family: "bux-icons";
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: -0.3125rem;
}
.bux-pagination .bux-pagination__link--previous:before {
  content: "\f004";
  font-weight: 400;
}
.bux-pagination .bux-pagination__link--next:after {
  content: "\f005";
  font-weight: 400;
}
.bux-pagination .bux-pagination__link--truncated:before {
  font-family: "bux-icons";
  content: "\f00d";
  font-size: 0.75rem;
  color: #212325;
}
.bux-pagination .bux-pagination__link--current {
  background-color: #eff1f2;
  color: #212325;
  cursor: default;
  border-bottom: 4px #ba0c2f solid;
  padding-bottom: 4px;
}

.bux-pagination--alt {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  margin-top: 4px;
}
.bux-pagination--alt .bux-pagination__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bux-pagination--alt .bux-pagination__link {
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  color: #ba0c2f;
  text-decoration: none;
  height: 40px;
  width: 40px;
  padding: 8px;
  text-align: center;
}
.bux-pagination--alt .bux-pagination__link:hover {
  background-color: #fff;
  color: #212325;
}
.bux-pagination--alt .bux-pagination__link:focus {
  position: relative;
  outline: 2px solid #3492b8;
}
.bux-pagination--alt .bux-pagination__link--truncated:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #ba0c2f;
}
.bux-pagination--alt .bux-pagination__link--previous:before,
.bux-pagination--alt .bux-pagination__link--next:after {
  font-family: "bux-icons";
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: -0.3125rem;
}
.bux-pagination--alt .bux-pagination__link--previous:before {
  content: "\f004";
  font-weight: 400;
}
.bux-pagination--alt .bux-pagination__link--next:after {
  content: "\f005";
  font-weight: 400;
}
.bux-pagination--alt .bux-pagination__link--truncated:before {
  font-family: "bux-icons";
  content: "\f00d";
  font-size: 0.75rem;
  color: #212325;
}
.bux-pagination--alt .bux-pagination__link--current {
  background-color: #fff;
  color: #212325;
  cursor: default;
  border-bottom: 4px #ba0c2f solid;
  padding-bottom: 4px;
}

.bux-pagination--reverse {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  margin-top: 4px;
}
.bux-pagination--reverse .bux-pagination__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bux-pagination--reverse .bux-pagination__link {
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  height: 40px;
  width: 40px;
  padding: 8px;
  text-align: center;
}
.bux-pagination--reverse .bux-pagination__link:hover {
  background-color: #fff;
  color: #212325;
}
.bux-pagination--reverse .bux-pagination__link:focus {
  position: relative;
  outline: 2px solid #3492b8;
}
.bux-pagination--reverse .bux-pagination__link--truncated:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.bux-pagination--reverse .bux-pagination__link--previous:before,
.bux-pagination--reverse .bux-pagination__link--next:after {
  font-family: "bux-icons";
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: -0.3125rem;
}
.bux-pagination--reverse .bux-pagination__link--previous:before {
  content: "\f004";
  font-weight: 400;
}
.bux-pagination--reverse .bux-pagination__link--next:after {
  content: "\f005";
  font-weight: 400;
}
.bux-pagination--reverse .bux-pagination__link--truncated:before {
  font-family: "bux-icons";
  content: "\f00d";
  font-size: 0.75rem;
  color: #212325;
}
.bux-pagination--reverse .bux-pagination__link--current {
  background-color: #fff;
  color: #212325;
  cursor: default;
  border-bottom: 4px #ba0c2f solid;
  padding-bottom: 4px;
}

.bux-panel {
  background-color: #f6f7f8;
  padding: 32px;
}
.bux-panel .bux-button {
  margin-top: 16px;
  display: inline-block;
}

*.bux-panel__heading {
  margin-bottom: 16px;
}

.bux-paragraph, .bux-qa__answer-text, p, .bux-listicle__text {
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 16px;
  line-height: 1.5;
}

.bux-qa__item {
  margin-bottom: 32px;
}
.bux-qa__item:last-child {
  margin-bottom: 0;
}

.bux-qa__question {
  display: flex;
}

.bux-qa__answer {
  display: flex;
}

.bux-qa__question-marker,
.bux-qa__answer-marker {
  background: #eff1f2;
  height: 32px;
  min-width: 32px;
  margin-right: 16px;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.675;
  text-align: center;
}

.bux-qa__question-marker {
  line-height: 1.625;
}

.bux-qa__question-text {
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  color: #212325;
  margin: 0;
  margin-bottom: 8px;
  margin: 4px 0 0;
}
.bux-qa__question-text a {
  text-decoration: none;
  color: #ba0c2f;
}
.bux-qa__question-text a:hover {
  text-decoration: underline;
}

.bux-qa__answer-text {
  margin-bottom: 0;
}

.bux-qa--storytelling .bux-qa__question {
  margin-top: 32px;
  margin-bottom: 16px;
}
.bux-qa--storytelling .bux-qa__question-text {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}
.bux-qa--storytelling .bux-qa__answer {
  margin-bottom: 0;
}
.bux-qa--storytelling .bux-qa__answer-text {
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1rem;
}

.bux-quote {
  border-left: 4px solid #ba0c2f;
  padding: 0 0 0 32px;
}
.bux-quote p {
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 2rem;
  margin: 0 0 16px;
  padding: 0;
}
@media (min-width: 640px) {
  .bux-quote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
.bux-quote__image {
  align-self: flex-start;
  margin: 0 0 24px 0;
  max-width: 100px;
  position: relative;
}
@media (min-width: 640px) {
  .bux-quote__image {
    margin: 0 24px 0 0;
    position: absolute;
  }
}
.bux-quote__author {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.bux-quote__source {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.bux-search {
  position: relative;
  height: 36px;
}

.bux-search__input {
  padding: 0.375rem 0.5rem 0.3125rem;
  border-bottom: 2px solid #3f4443;
  color: #3f4443;
  line-height: 1;
  height: 36px;
  width: 100%;
}
.bux-search__input::placeholder {
  color: #646a6e;
  font-size: 0.9375rem;
}

.bux-search__submit {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  height: 30px;
  width: 36px;
  font-size: 18px;
  position: absolute;
  right: 2px;
  padding-top: 6px;
  margin-top: 2px;
}
.bux-search__submit:after {
  content: "\f012";
  font-family: "bux-icons";
}
.bux-search__submit:focus {
  outline: 2px solid #3492b8;
}

div.bux-container--sidebar-nav {
  padding: 0;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-closed {
  display: none;
}
div.bux-container--sidebar-nav nav.disclosure-nav button {
  background-color: transparent;
  border: none;
}
div.bux-container--sidebar-nav nav.disclosure-nav button::after {
  font-family: bux-icons;
  content: "\f010";
  color: #212325;
  position: relative;
  top: -1px;
  left: 4px;
}
div.bux-container--sidebar-nav nav.disclosure-nav button[aria-expanded=true]::after {
  content: "\f011";
}
div.bux-container--sidebar-nav nav.disclosure-nav button[aria-expanded=false]::after {
  content: "\f010";
}
div.bux-container--sidebar-nav nav.disclosure-nav button:focus {
  outline: 2px solid #3492b8;
  outline-offset: 0px;
}
div.bux-container--sidebar-nav nav.disclosure-nav a,
div.bux-container--sidebar-nav nav.disclosure-nav span {
  color: #212325;
  text-decoration: none;
}
div.bux-container--sidebar-nav nav.disclosure-nav ul,
div.bux-container--sidebar-nav nav.disclosure-nav ol {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
div.bux-container--sidebar-nav nav.disclosure-nav ul.disclosure-nav-submenu-open,
div.bux-container--sidebar-nav nav.disclosure-nav ol.disclosure-nav-submenu-open {
  display: block;
}
div.bux-container--sidebar-nav nav.disclosure-nav ul.disclosure-nav-submenu-closed,
div.bux-container--sidebar-nav nav.disclosure-nav ol.disclosure-nav-submenu-closed {
  display: none;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul {
  background-color: #fff;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul li {
  position: relative;
  margin: 0;
  border-bottom: 2px solid #eff1f2;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link {
  display: block;
  border-bottom: none;
  white-space: normal;
  border-left: 4px solid rgba(0, 0, 0, 0);
  padding: 12px;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a.disclosure-nav-item-with-submenu,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span.disclosure-nav-item-with-submenu,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link.disclosure-nav-item-with-submenu {
  padding-right: 2.45rem;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a:hover, div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a:focus,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span:hover,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span:focus,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:hover,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:focus {
  color: #212325;
  border-left-color: #3f4443;
  background-color: #f6f7f8;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a:focus,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span:focus,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:focus {
  outline: 2px solid #3492b8;
  outline-offset: 0px;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul a.bux-sidebar-nav__link--active,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul span.bux-sidebar-nav__link--active,
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link.bux-sidebar-nav__link--active {
  border-left-color: #ba0c2f;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button {
  position: absolute;
  right: 0.45rem;
  top: 0.45rem;
  padding: 0;
  height: 2rem;
  width: 2rem;
  background-color: #f6f7f8;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link {
  position: static;
  right: auto;
  top: auto;
  float: none;
  text-align: left;
  width: 100%;
  height: auto;
  background-color: #fff;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link::after {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  left: auto;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button.disclosure-nav-button-no-link:hover::after {
  border-right-color: #212325;
  border-bottom-color: #212325;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button:hover {
  background-color: #3f4443;
  cursor: pointer;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button::after {
  content: "\f005";
  font-family: "bux-icons";
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  top: 2px;
  left: 7px;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button:hover::after {
  color: #fff;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button[aria-expanded=true]::after {
  transform: rotate(90deg);
  transition: 120ms transform ease-in-out;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul button[aria-expanded=false]::after {
  transform: rotate(0deg);
  transition: 120ms transform ease-in-out;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul ul {
  font-weight: normal;
  margin-top: 12px;
}
div.bux-container--sidebar-nav nav.disclosure-nav.disclosure-nav-orientation-vertical ul ul li {
  margin-top: 0;
  padding-left: 16px;
  border-bottom: none;
}
div.bux-container--sidebar-nav__mobile-hidden {
  display: none;
}
@media (min-width: 960px) {
  div.bux-container--sidebar-nav__mobile-hidden {
    display: block;
  }
}

.bux-skip-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: auto;
  right: auto;
  top: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  font-family: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #ba0c2f;
  border-bottom-color: #ba0c2f;
}
.bux-skip-navigation:focus {
  border-bottom: none;
  outline: 2px solid #3492b8;
  outline-offset: 2px;
}
.bux-skip-navigation:hover {
  color: #212325;
  border-bottom-color: #212325;
  background-color: #eff1f2;
}
.bux-skip-navigation:focus {
  color: #ba0c2f;
}
.bux-skip-navigation:visited {
  color: #660099;
  border-bottom-color: #660099;
}
.bux-skip-navigation:focus {
  line-height: auto;
  height: auto;
  width: auto;
  padding: 0 12px;
  clip: auto;
  z-index: 2400;
  text-align: center;
  border-top: 0;
  margin: 4px;
  top: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.7);
}

.bux-social-links .bux-social-links__item,
.bux-social-links--light .bux-social-links__item,
.bux-social-links--white .bux-social-links__item {
  display: inline-block;
  margin-right: 8px;
}
.bux-social-links .bux-social-links__item:last-child,
.bux-social-links--light .bux-social-links__item:last-child,
.bux-social-links--white .bux-social-links__item:last-child {
  margin-right: 0;
}
.bux-social-links .bux-social-links__link,
.bux-social-links--light .bux-social-links__link,
.bux-social-links--white .bux-social-links__link {
  color: inherit;
  border-bottom: none;
  color: #212325;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 44px;
  height: 44px;
}
.bux-social-links .bux-social-links__link:hover, .bux-social-links .bux-social-links__link:focus,
.bux-social-links--light .bux-social-links__link:hover,
.bux-social-links--light .bux-social-links__link:focus,
.bux-social-links--white .bux-social-links__link:hover,
.bux-social-links--white .bux-social-links__link:focus {
  color: inherit;
  border-bottom-color: none;
  background-color: inherit;
}
.bux-social-links .bux-social-links__link:hover,
.bux-social-links--light .bux-social-links__link:hover,
.bux-social-links--white .bux-social-links__link:hover {
  background-color: #dfe3e5;
  color: #212325;
}
.bux-social-links .bux-social-links__link:focus,
.bux-social-links--light .bux-social-links__link:focus,
.bux-social-links--white .bux-social-links__link:focus {
  outline: 2px solid #3492b8;
  background-color: #dfe3e5;
  color: #212325;
}
.bux-social-links .bux-social-links__link span,
.bux-social-links--light .bux-social-links__link span,
.bux-social-links--white .bux-social-links__link span {
  font-size: 1.25rem;
  line-height: 0;
}

.bux-social-links--dark .bux-social-links__item {
  display: inline-block;
  margin-right: 8px;
}
.bux-social-links--dark .bux-social-links__item:last-child {
  margin-right: 0;
}
.bux-social-links--dark .bux-social-links__link {
  color: inherit;
  border-bottom: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 44px;
  height: 44px;
}
.bux-social-links--dark .bux-social-links__link:hover, .bux-social-links--dark .bux-social-links__link:focus {
  color: inherit;
  border-bottom-color: none;
  background-color: inherit;
}
.bux-social-links--dark .bux-social-links__link:hover {
  background-color: #646a6e;
  color: #fff;
}
.bux-social-links--dark .bux-social-links__link:focus {
  outline: 2px solid #3492b8;
  background-color: #646a6e;
  color: #fff;
}
.bux-social-links--dark .bux-social-links__link span {
  font-size: 1.25rem;
  line-height: 0;
}

.bux-table {
  width: 100%;
  display: table;
  overflow-x: auto;
}
.bux-table caption {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: calc(1.375rem * 1.125);
  margin-bottom: 16px;
  text-align: left;
}
@media (min-width: 640px) {
  .bux-table caption {
    font-size: 1.5rem;
    line-height: calc(1.5rem * 1.125);
  }
}
.bux-table caption p {
  font-family: inherit;
}
.bux-table th {
  font-size: 1rem;
  padding: 8px 16px;
  border-bottom: 2px solid #646a6e;
  background-color: #fff;
  text-align: left;
  font-weight: 700;
}
.bux-table tbody th {
  font-weight: 700;
  border-bottom: none;
  border-right: 2px solid #646a6e;
}
.bux-table tr {
  border-bottom: 2px solid #eff1f2;
}
.bux-table td {
  font-size: 1rem;
  padding: 16px;
  font-weight: 400;
}

.bux-table--striped tr:nth-child(even) {
  background-color: #f6f7f8;
}

.bux-table--compact th,
.bux-table--compact td {
  font-size: 0.875rem;
  padding: 8px;
}

.bux-table--sortable .sort-by {
  cursor: pointer;
}
.bux-table--sortable .sort-by button {
  color: #212325;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.bux-table--sortable .sort-by button:after {
  content: "\f00f";
  font-family: "bux-icons";
  color: #212325;
  margin-left: 4px;
}
.bux-table--sortable .th-sort-asc,
.bux-table--sortable .th-sort-desc {
  background-color: #f6f7f8;
  margin-left: 4px;
}
.bux-table--sortable .th-sort-asc button::after {
  content: "\f011";
  font-family: "bux-icons";
}
.bux-table--sortable .th-sort-desc button::after {
  content: "\f010";
  font-family: "bux-icons";
}
.bux-table--sortable.bux-table--announcement-region {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.bux-tabpanel__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  outline: 0;
  overflow: auto hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: auto;
  will-change: scroll-position;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}
.bux-tabpanel__tabs::-webkit-scrollbar {
  display: none;
}
@media (max-width: 640px) {
  .bux-tabpanel__tabs {
    flex-direction: column;
    width: 100%;
  }
}
.bux-tabpanel__tabs--scrollable {
  display: flex;
  height: auto;
  width: 100%;
}
.bux-tabpanel__tab {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #fff;
  color: #212325;
  cursor: pointer;
  padding: 12px 16px;
  margin-right: 1px;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}
@media (max-width: 640px) {
  .bux-tabpanel__tab {
    width: 100%;
    text-align: left;
  }
}
.bux-tabpanel__tab::after {
  background: #eff1f2;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.bux-tabpanel__tab[aria-selected=true] {
  border: 2px solid #eff1f2;
  outline: none;
}
@media (max-width: 640px) {
  .bux-tabpanel__tab[aria-selected=true] {
    border: 2px solid transparent;
  }
}
.bux-tabpanel__tab[aria-selected=true]::after {
  background: #ba0c2f;
}
.bux-tabpanel__tab:focus {
  border: 2px solid #eff1f2;
  outline: 2px solid #3492b8;
  outline-offset: -2px;
}
.bux-tabpanel__tab:focus::after {
  background: #ba0c2f;
}
@media (hover: hover) and (pointer: fine) {
  .bux-tabpanel__tab:hover {
    background-color: #f6f7f8;
    border-color: #f6f7f8;
    color: #212325;
  }
  .bux-tabpanel__tab:hover::after {
    background: #3f4443;
  }
}
.bux-tabpanel__panel {
  padding: 32px;
  border-bottom: 2px solid #eff1f2;
  font-family: "BuckeyeSerif", "Georgia", serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: -4px;
}
.bux-tabpanel__panel:focus {
  outline: 2px solid #3492b8;
  outline-offset: -2px;
  border-color: #cfd4d8;
}

a.bux-text-link {
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  color: #212325;
  font-weight: 400;
  text-decoration: none;
  margin-right: 25px;
  position: relative;
}
a.bux-text-link:after {
  content: "\f005";
  font-size: 0.875rem;
  font-family: "bux-icons";
  color: #ba0c2f;
  font-weight: 600;
  padding-left: 8px;
  transition-duration: 0.05s;
  position: absolute;
  top: 0.09375rem;
}
a.bux-text-link:hover {
  color: #ba0c2f;
  text-decoration: none;
}
a.bux-text-link:hover:after {
  padding-left: 16px;
  transition-duration: 0.05s;
}
a.bux-text-link:focus {
  outline: 2px solid #3492b8;
}

.bux-tile {
  position: relative;
}

.bux-tile__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bux-tile__content {
  background-color: #f6f7f8;
  padding: 24px;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.bux-tile__std_icon {
  max-width: 4.5rem;
  margin: 0 auto;
  margin-bottom: 16px;
}

.bux-tile__ui_icon {
  max-width: 32px;
  margin: 0 auto 16px;
}
.bux-tile__ui_icon::before {
  color: #ba0c2f;
  font-size: 32px;
  width: 32px;
  margin: 0 auto;
  margin-bottom: 16px;
  display: block;
}

.bux-tile__body {
  text-align: center;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  margin-top: 16px;
  margin-bottom: 8px;
}

.bux-tile__text,
bux-tile__header .bux-tile__text {
  color: #ba0c2f;
  display: block;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 1.375rem;
  line-height: 1.35;
  font-weight: 700;
  text-align: center;
  pointer-events: all;
  text-decoration: none;
  margin: 0;
  hyphens: auto;
}
@media (min-width: 640px) {
  .bux-tile__text,
  bux-tile__header .bux-tile__text {
    font-size: 1.5rem;
    line-height: 1.35;
  }
}
.bux-tile__text:hover,
bux-tile__header .bux-tile__text:hover {
  text-decoration: none;
}
.bux-tile__text:focus,
bux-tile__header .bux-tile__text:focus {
  outline: 2px solid #3492b8;
}

.bux-tile__header {
  margin: 0;
}

.bux-tile:hover .bux-tile__content {
  border-bottom: 4px solid #ba0c2f;
  transition-duration: 0.05s;
  margin-bottom: -4px;
}
.bux-tile:hover:focus {
  outline: 2px solid #3492b8;
}

.bux-timeline {
  position: relative;
  padding: 24px;
}
.bux-timeline::before {
  content: "";
  margin-left: 4px;
  position: absolute;
  top: 0;
  left: 24px;
  height: 100%;
  width: 4px;
  background: #cfd4d8;
}
.bux-timeline__item::after {
  margin-left: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #eff1f2;
  border-width: 1rem;
  top: 0.625rem;
}
.bux-timeline__item {
  position: relative;
  margin-bottom: 48px;
}
.bux-timeline__item:last-child {
  margin-bottom: 0;
}
.bux-timeline__marker {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 0 4px #ba0c2f;
  border-radius: 50%;
  margin-top: 1.25rem;
}
.bux-timeline__content {
  padding: 16px 24px;
  margin-left: 2rem;
  background-color: #fff;
  border: 2px solid #eff1f2;
  border-top: 0;
  box-shadow: 0 -4px 0 0 #ba0c2f;
}
.bux-timeline__content p:last-child {
  margin-bottom: 0;
}
.bux-timeline__heading {
  font-size: 1.375rem;
  color: #212325;
  font-weight: 700;
  margin-bottom: 4px;
}
@media (min-width: 640px) {
  .bux-timeline__heading {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
.bux-timeline__date {
  font-size: 1.125rem;
  color: #646a6e;
  font-weight: 600;
  margin-bottom: 16px;
}

.bux-timeline--right::before {
  left: auto;
  right: calc(24px + 4px);
}
.bux-timeline--right .bux-timeline__item::after {
  margin-left: 0;
  right: 0;
  border-left-color: #eff1f2;
  border-right-color: transparent;
}
.bux-timeline--right .bux-timeline__marker {
  margin-left: 0;
  right: 0;
}
.bux-timeline--right .bux-timeline__content {
  margin-left: 0;
  margin-right: 2rem;
}

@media (min-width: 640px) {
  .bux-timeline--center::before {
    left: 50%;
    margin-left: 0;
  }
  .bux-timeline--center .bux-timeline__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .bux-timeline--center .bux-timeline__row:last-child {
    margin-bottom: 0;
  }
  .bux-timeline--center .bux-timeline__item {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 12px;
    width: 100%;
  }
  .bux-timeline--center .bux-timeline__item::after {
    margin-left: -24px;
    left: 100%;
    border-left-color: #eff1f2;
    border-right-color: transparent;
  }
  .bux-timeline--center .bux-timeline__item .bux-timeline__marker {
    margin-left: -4px;
    left: 100%;
  }
  .bux-timeline--center .bux-timeline__item .bux-timeline__content {
    margin-left: 0;
    margin-right: 12px;
  }
  .bux-timeline--center .bux-timeline__item--even {
    margin-left: auto;
  }
  .bux-timeline--center .bux-timeline__item--even::after {
    margin-left: -16px;
    left: auto;
    border-right-color: #eff1f2;
    border-left-color: transparent;
  }
  .bux-timeline--center .bux-timeline__item--even .bux-timeline__marker {
    left: auto;
    margin-left: -16px;
  }
  .bux-timeline--center .bux-timeline__item--even .bux-timeline__content {
    margin-right: 0;
    margin-left: 16px;
  }
}