.bux-colors__group-label {
	font-size: $ts-22;
	font-weight: 600;
	margin: rem-calc(12 0 4);
}

.bux-colors__group {
	display: flex;
	flex-wrap: wrap;
}

.bux-colors__item {
	width: rem-calc(140);
	margin-right: $sp-16;
	margin-bottom: $sp-32;
	border: 1px solid $gray-light-60;
	&:last-child {
		margin-right: 0;
	}
}

.bux-colors__swatch {
	height: rem-calc(80);
}

.bux-colors__label {
	padding: $sp-4 $sp-8;
}

.bux-colors__code {
	display: block;
	font-size: $ts-xs;
	font-family: $code;
}
