@mixin hero (
    $bg-color: $gray-light-90,
    $title-color: $gray-dark-80,
    $text-color: $gray-dark-40,
) {

    .bux-hero__content {
        background-color: $bg-color;
        padding: $sp-24;
        @include breakpoint(lg) {
            padding: $sp-32 $sp-32 $sp-32 $sp-64;
        }
    }

    .bux-hero__header {
        display: block;
        font-family: $serif;
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: 900;
        color: $title-color;
        overflow: hidden;
        @include breakpoint(lg) {
            font-size: 2rem;
            line-height: 2.25rem;
        }

        span {
            display: block;
        }
    }
    
    .bux-hero__subheader {
        display: block;
        font-family: $sans;
        font-size: rem-calc(20);
        line-height: rem-calc(26);
        font-weight: 600;
        margin-bottom: $sp-24;
        color: $text-color;
        overflow: hidden;
        @include breakpoint(lg) {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        span {
            display: block;
        }
    }
}

.bux-hero {
    @include hero();
    position: relative;
    display: flex;

    &__carousel-items {
        margin: 0;
        @include breakpoint(lg) {
            padding: 0 15px 0;
        }
        width: 100%;
    }

    &__content {
        width: 100%;
        order: 1;
    }

    &__container {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        position: relative;
        transition: 1s;
        opacity: 0;
        z-index: 1;
        -webkit-transition: opacity 0.5s;
        -o-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: none;
    }

    &__image {
        order: 0;
        height: 0;
        position: relative; 
        overflow: hidden;
        
        img {
            width: 100%;
            object-fit: cover;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            transform: none;
            width: 100%;
        }
        &--3x2 img {
            aspect-ratio: 3/2;
        }

        &--16x9 img {
            aspect-ratio: 16/9;
        }
    }

    &__button {
        width: rem-calc(44);
        height: rem-calc(44);
        position: absolute;
        background-color: $gray-light-80;
        border: solid 2px $gray-light-60;
        border-radius: 0;
        color: $gray-dark-60;
        font-size: $ts-28;
        cursor: pointer;
        z-index: 99;

        &:hover {
            background-color: $gray-dark-60;
            color: $white;
            border-color: $gray-dark-60;
        }
        &:focus {
            outline: 2px solid $focus;
            outline-offset: -2px;
        }
        .icon {
            display: block;
            padding-top: rem-calc(1);
        }
        &--left {
            left: 0;
        }
    
        &--right {
            right: 0;
        }
    }

    &__tabs {
        display: none;

        @include breakpoint(lg) {
            align-items: center;
            bottom: $sp-16;
            display: flex;
            justify-content: center;
            left: auto;
            position: absolute;
            right: auto;
            width: 100%;
            z-index: 99;
        }
    }
    
    &__tab {
        height: rem-calc(20);
        width: rem-calc(20);
        background: $white;
        border: 2px solid $gray-dark-40;
        border-radius: 100%;
        margin-left: 10px;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            background: $gray-dark-40;
            border: none;
        }

        &--active {
            background: $gray-dark-60;
            border: 2px solid $white;
            height: rem-calc(22);
            width: rem-calc(22);
        }
    }

}

.showing {
    opacity: 1;
    z-index: 2;
    display: flex;
}

.bux-hero--card-right {
    .showing {
        img {
            animation: fadeInLeft 1s;
        }
        .bux-hero__header {
            span {
                animation: slideUp 1s;
            }
        }
        .bux-hero__subheader {
            span {
                animation: slideUp 1s;
            }
        }
    }
}
.bux-hero--card-left {
    .showing {
        img {
            animation: fadeInRight 1s;
        }
        .bux-hero__header {
            span {
                animation: slideUp 1s;
            }
        }
        .bux-hero__subheader {
            span {
                animation: slideUp 1s;
            }
        }
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 40px;
    }
    100% {
        opacity: 1; 
        left: 0;
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        left: -40px;
    }
    100% {
        opacity: 1; 
        left: 0;
    }
}
@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }
    100% {
        opacity: 1; 
        transform: translateY(0%);
    }
}

.bux-hero-container {
    position: relative;
}

.bux-hero--scarlet {
    @include hero($scarlet, $white, $white)
}

.bux-hero--white {
    @include hero($white, $gray-dark-80, $gray-dark-40)
}

.bux-hero {
    @include breakpoint(lg) {
        flex-direction: row;
        align-items: stretch;

        &__container {
            flex-direction: row;
            overflow-y: hidden;
        }

        &__content {
            order: 0;
            flex-shrink: 2;
        }

        &__image {
            order: 1;
            padding-top: 100%;

            img {
                aspect-ratio: auto;
                height: 100%;
                width: 100%;
            }
        }
    }
    &--card-right {
        .bux-hero__content {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
        }
        &.bux-hero--3x2 {
            .bux-hero__content {
                @include breakpoint(lg) {
                    padding: $sp-32 $sp-64 $sp-32 $sp-32;
                }
            }
        }
        &.bux-hero--16x9 {
            .bux-hero__content {
                @include breakpoint(lg) {
                    padding: $sp-16 $sp-64 $sp-16 $sp-16;
                }
            }
        }
    }
    &--card-left {
        .bux-hero__content {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
        }
        &.bux-hero--3x2 {
            .bux-hero__content {
                @include breakpoint(lg) {
                    padding: $sp-32 $sp-32 $sp-32 $sp-64;
                }
            }
        }
        &.bux-hero--16x9 {
            .bux-hero__content {
                @include breakpoint(lg) {
                    padding: $sp-16 $sp-16 $sp-16 $sp-64;
                }
            }
        }
    }
    &--card-full {
        .bux-hero__content {
            max-width: 800px;
            padding: $sp-32;
            text-align: center;
        }
        .bux-text-link {
            cursor: pointer;
            display: inline;

            &::after {
                display: inline;
                font-size: rem-calc(20);
                font-weight: 400;
                position: absolute;
                top: auto;
            }
        }
        .bux-hero__header {
            font-size: rem-calc(28);
            line-height: rem-calc(34);
            @include breakpoint(lg) {
                font-size: 2rem;
                line-height: 2.25rem;
            }
        }
        .bux-hero__subheader {
            margin-bottom: 0;
        }
    }
    &--3x2 {
        .bux-hero__image {
            padding-top: 66.66%;
        }
        @include breakpoint(lg) {
            .bux-hero__content {
                width: 40%;
            }
            .bux-hero__image-container {
                width: 60%;
            }
        }
    }

    &--16x9 {
        .bux-hero__image {
            padding-top: 56.25%;
        }
        @include breakpoint(lg) {
            .bux-hero__content {
                width: 40%;
            }
            .bux-hero__image-container {
                width: 60%;
            }
        }
    }

    &--card-full {
        .bux-hero__container {
            align-items: center;
            flex-direction: column;
        }
        .bux-hero__content {
            z-index: 2;
            @include breakpoint(lg) {
                margin-top: -70px;
                order: 1;
                width: auto;
            }
        }
        .bux-hero__image-container {
            width: 100%;
        }
        .bux-hero__image {
            padding-top: 56.25%;
            position: relative;
            width: 100%;
            z-index: 1;
            @include breakpoint(lg) {
                order: 0;
            }
        }
        img {
            aspect-ratio:  16/9;
            left: 0;
            position: absolute;
            top: 0;
        }
        video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .bux-hero__video-button {
            background: none;
            border: none;
            border-radius: 0;
            cursor: pointer;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 3;

            &:focus {
                outline: 2px solid $focus;
                outline-offset: -2px;
            }

            &-icon {
                background: $white;
                border: 2px solid white;
                border-radius: 50%;
                color: $scarlet;
                font-family: bux-icons !important;
                font-size: rem-calc(31);
                font-weight: 800;
                height: 50px;
                left: 0;
                line-height: 2.5rem;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 50px;
                @include breakpoint(lg) {
                    font-size: rem-calc(62);
                    height: 100px;
                    line-height: 5rem;
                    width: 100px;
                }
                &--play {
                    visibility: hidden;

                    .bux-icon {
                        display: block;
                        margin: 2px 0 0 $sp-4;
                        @include breakpoint(lg) {
                            margin: $sp-8 0 0 $sp-8;
                        }
                    }
                }
                &--pause {
                    visibility: hidden;

                    .bux-icon {
                        display: block;
                        margin: $sp-4 0 0 0;
                        @include breakpoint(lg) {
                            margin: $sp-8 0 0 0;
                        }
                    }
                }
            }

            &:hover,
            &:focus {
                .bux-hero__video-button-icon--play {
                    visibility: hidden;
                }
                .bux-hero__video-button-icon--pause {
                    line-height: rem-calc(35);
                    visibility: visible;
                    @include breakpoint(lg) {
                        line-height: rem-calc(73);
                    }
                }
            }
        }
        .bux-hero__video-play {
            &:hover,
            &:focus {
                .bux-hero__video-button-icon--play {
                    visibility: visible;
                }
                .bux-hero__video-button-icon--pause {
                    visibility: hidden;
                }
            }
        }
    }
}
@include breakpoint(lg) {
    .bux-hero--card-right {

        .bux-hero__image {
            order: 1;
        }

        .bux-hero__content {
            order: 2;
        }
    }
}
