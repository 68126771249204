.bux-featured-card {

  flex-direction: column-reverse;

  @include breakpoint(md) {
    flex-direction: row;
  }

  .bux-featured-card--image {

      @include breakpoint(md) {
          flex-basis: 66.6%;
      }
    img {
      height: 100%;
      object-fit: cover;
      max-width: 100%;

      @include breakpoint(md) {
        width: 100%;
      }
      
    }
  }

  .bux-featured-card--wrapper {
    align-self: center;
    max-width: 100%;
    margin-left: 0;

    @include breakpoint(md) {
      margin-right: -8.33%;
    }
    
  }

  &--image-left {

    @include breakpoint(md) {
      flex-direction: row-reverse;
      .bux-featured-card--wrapper {
      margin-left: -8.33%;
        margin-right: 0;
      }
    }

  }

}
