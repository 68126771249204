.bux-switch {
  @include form-element;

  &__option {
    display: grid;
    grid-template-columns: 45px auto;
    gap: $sp-12;

    label {
      margin-top: 4px;
    }

    & + & {
      margin-top: $sp-8;
    }

    input {
      appearance: none;
      cursor: pointer;
      background-color: $gray-dark-80;
      border: none;
      border-radius: 32px;
      height: 24px;
      width: 45px;
      display: grid;
      place-content: center;
      margin: 0;
      transform: translateY(3px);

      &:checked {
        background-color: $scarlet;
      }

      &:before {
        content: "";
        height: 18px;
        width: 18px;
        background-color: white;
        transition: 120ms transform ease-in-out;
        border-radius: 50%;
        transform: translate(-10px, 4px);
      }

      &:checked:before {
        transform: translate(10px, 4px);
      }

      &:after {
        content: "";
        height: 9px;
        width: 2px;
        border-radius: 2px;
        background-color: $white;
        transition: 120ms transform ease-in-out;
        transform: translate(0, -6px);
      }

      &:checked:after {
        background-color: $scarlet;
        transform: translate(18.5px, -9px);
      }

      &:focus {
        outline: 2px solid $focus;
      }
    }

    &--disabled input {
      background-color: $gray-light-40;
    }

    &--disabled label {
      color: $gray-light-40;
    }
  }
  &__error-message {
    @include form-error-message;
    margin-top: 8px;
  }
}
