.bux-listicle__item {
	margin-bottom: $sp-32;
	&:last-child {
		margin-bottom: 0;
	}
}

.bux-listicle__title {
	display: flex;
}

.bux-listicle__title-number {
	background: $gray-light-60;
	color: $gray-dark-80;
	height: $sp-32;
	min-width: $sp-32;
	margin-right: $sp-16;
	font-size: $ts-20;
	line-height: 1.675;
	text-align: center;
	font-weight: 700;
	display: block;
}

.bux-listicle__title-text {
	@include heading($ts-20, 26, 700);
	margin-top: 3px;
	display: block;
}

.bux-listicle__text {
	@extend .bux-paragraph;
	position: relative;
	padding-left: calc(#{$sp-32} + #{$sp-16});
}

.bux-listicle__item--unordered {

	.bux-listicle__title {
		margin-bottom: 0;
	}

	.bux-listicle__title-text {
		margin-top: 0;
		margin-bottom: $sp-16;
	}

	.bux-listicle__title-number {
		display: none;
	}

	.bux-listicle__text {
		left: 0;
		padding-left: 0;
	}
}
