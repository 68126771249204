.bux-image {
    display: block;
    width: 100%;

    &__float-left {
        float: left;
        margin-right: $sp-32;
    }

    &__float-right {
        float: right;
        margin-left: $sp-32;
    }

    &--16x9 {
        aspect-ratio: 16/9;
        width: 100%;
        object-fit: cover;
    }
    &--1x1 {
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover;
    }
    &--3x2 {
        aspect-ratio: 3/2;
        width: 100%;
        object-fit: cover;
    }
}

.bux-image-caption {
    border-bottom: 2px solid $gray-light-80;
    margin-bottom: $sp-32;
}

.bux-image-caption__text {
    font-family: $sans;
    font-size: 14px;
    line-height: 18px;
    margin: $sp-8 0 10px;
}
