// Global spacing variables.
$sp-4: 4px;
$sp-8: 8px;
$sp-12: 12px;
$sp-16: 16px;
$sp-24: 24px;
$sp-32: 32px;
$sp-48: 48px;
$sp-64: 64px;
$sp-96: 96px;

// Breakpoints.
$breakpoints: (
  sm: 0,
  md: 640px,
  lg: 960px,
  xl: 1216px,
  xxl: 1440px,
);

// Global grid gutter.
$gutter: $sp-16;
$container-gutter: $sp-64;

// Global max-width for containers.
$max-width: map-get($breakpoints, xxl);
