.bux-panel {
	background-color: $gray-light-90;
	padding: $sp-32;

	.bux-button {
		margin-top: $sp-16;
		display: inline-block;
	}
}

*.bux-panel__heading {
	@extend .bux-h2;
	margin-bottom: $sp-16;
}
