div.bux-container--sidebar-nav {

    padding: 0;

    nav.disclosure-nav {
        &.disclosure-nav-closed {
            display: none;
        }

        button {
            background-color: transparent;
            border: none;

            &::after {
                // Down arrow
                font-family: bux-icons;
                content: "\f010";
                color: $gray-dark-80;
                position: relative;
                top: -1px;
                left: 4px;
            }

            &[aria-expanded="true"] {
                &::after {
                    // Up arrow
                    content: "\f011";
                }
            }

            &[aria-expanded="false"] {
                &::after {
                    // Down arrow
                    content: "\f010";
                }
            }

            &:focus {
                outline: 2px solid $focus;
                outline-offset: 0px;
            }
        }

        a,
        span {
            color: $gray-dark-80;
            text-decoration: none;
        }

        ul,
        ol {
            font-family: $sans;
            font-size: $ts-base;
            font-weight: 700;

            &.disclosure-nav-submenu-open {
                display: block;
            }

            &.disclosure-nav-submenu-closed {
                display: none;
            }
        }

        &.disclosure-nav-orientation-vertical {
            ul {
                background-color: $white;

                li {
                    position: relative;
                    margin: 0;
                    border-bottom: 2px solid $gray-light-80;
                }

                a,
                span,
                button.disclosure-nav-button-no-link {
                    display: block;
                    border-bottom: none;
                    white-space: normal;
                    border-left: $sp-4 solid $clear;
                    padding: $sp-12;

                    &.disclosure-nav-item-with-submenu {
                        padding-right: 2.45rem;
                    }

                    &:hover,
                    &:focus {
                        color: $gray-dark-80;
                        border-left-color: $gray-dark-60;
                        background-color: $gray-light-90;
                    }

                    &:focus {
                        outline: 2px solid $focus;
                        outline-offset: 0px;
                    }

                    &.bux-sidebar-nav__link--active {
                        border-left-color: $scarlet;
                    }
                }

                button {
                    position: absolute;
                    right: 0.45rem;
                    top: 0.45rem;
                    padding: 0;
                    height: 2rem;
                    width: 2rem;

                    background-color: $gray-light-90;

                    &.disclosure-nav-button-no-link {
                        position: static;
                        right: auto;
                        top: auto;
                        float: none;
                        text-align: left;
                        width: 100%;
                        height: auto;
                        background-color: $white;

                        &::after {
                            position: absolute;
                            top: 1.25rem;
                            right: 1.25rem;
                            left: auto;
                        }

                        &:hover::after {
                            border-right-color: $gray-dark-80;
                            border-bottom-color: $gray-dark-80;
                        }
                    }

                    &:hover {
                        background-color: $gray-dark-60;
                        cursor: pointer;
                    }

                    &::after {
                        content: '\f005';
                        font-family: $icon;
                        font-size: 18px;
                        font-weight: normal;
                        position: absolute;
                        top: 2px;
                        left: 7px;
                    }

                    &:hover::after {
                        color: $white;
                    }

                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(90deg);
                            transition: 120ms transform ease-in-out;
                        }
                    }

                    &[aria-expanded="false"] {
                        &::after {
                            transform: rotate(0deg);
                            transition: 120ms transform ease-in-out;
                        }
                    }
                }

                ul {
                    // Second level submenus.
                    font-weight: normal;
                    margin-top: $sp-12;

                    li {
                        margin-top: 0;
                        padding-left: $sp-16;
                        border-bottom: none;

                        // ul {
                        //   // Third level submenus.
                        //   li {

                        //   }

                        //   ul {
                        //     // Fourth level submenus.
                        //     li {

                        //     }
                        //   }
                        // }
                    }
                }
            }
        }
    }
    &__mobile-hidden {
        display: none;
        @include breakpoint(lg) {
            display: block;
        }
    }
}
