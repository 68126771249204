html {
	font-family: $sans;
	font-weight: 400;
	font-size: $ts-base;
	line-height: 1.5;
	color: $gray-dark-80;
}

// Form styles for now, until form components are ready.
input {
	border: 1px solid $gray;
	border-radius: 0;

	&:focus {
		outline: none;
		border: 2px solid $focus;
	}
}
