@mixin header-variant($bg-color: $white, $txt-color: $gray-dark-80, $border-color: $gray-light-40) {
	background-color: $bg-color;
	color: $txt-color;
	padding: $sp-32 0;

	.bux-header__site-name-parent,
	.bux-header__site-name-slogan {
		font-family: $sans;
		font-size: $ts-base;
		font-weight: 400;
		line-height: rem-calc(22);

		@include breakpoint(md) {
			font-size: $ts-20;
			line-height: rem-calc(26);
		}
	}

	.bux-header__site-name-parent {
		padding-bottom: $sp-4;
	}

	.bux-header__site-name-slogan {
		padding-top: $sp-4;
	}

	.bux-header__site-name,
	& a {
		color: $txt-color;
		font-family: $sans;
		font-size: $ts-md;
		font-weight: 800;
		line-height: rem-calc(30);
		text-decoration: none;
		margin-top: 0;

		@include breakpoint(md) {
			font-size: $ts-lg;
			line-height: rem-calc(40);
		}
	}
}

// Apply mixins per variant.
.bux-header,
.bux-header--white {
	@include header-variant();
}

.bux-header--dark {
	@include header-variant($gray-dark-80, $white);
}
