.bux-checkbox {
  @include form-element;

  &__option {
    display: grid;
    grid-template-columns: 18px auto;
    gap: $sp-12;

    label {
      margin-top: 2px;
    }

    & + & {
      margin-top: $sp-8;
    }

    input[type="checkbox"] {
      appearance: none;
      display: grid;
      place-content: center;
      margin: 3px 3px 3px 0;
      border: solid 2px $gray-dark-80;
      width: 20px;
      height: 20px;

      &:before {
        content: "\f101";
        font-family: "bux-icons";
        color: $white;
        z-index: 99;
        font-size: 11px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
      }

      &:checked:before {
        transform: scale(1);
      }

      &:checked {
        border-color: $scarlet;
        background-color: $scarlet;
      }

      &:focus {
        outline: 2px solid $focus;
      }
    }

    &--disabled,
    &--disabled label {
      cursor: not-allowed;

      input[type="checkbox"],
      input[type="checkbox"]:checked {
        border: 2px solid $gray-light-40;
        background-color: $gray-light-80;

        &:checked:before {
          content: none;
        }

        + label {
          color: $gray-light-20;

          &:before,
          &:checked:before {
            content: none;
          }
        }
      }
    }

    &--error > input[type="checkbox"] {
      background: #ffeff2;
      border-color: $scarlet;
    }
  }

  &__error-message {
    @include form-error-message;
    margin-top: 5px;
  }
}
