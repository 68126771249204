.bux-qa__item {
	margin-bottom: $sp-32;
	&:last-child {
		margin-bottom: 0;
	}
}

.bux-qa__question {
	display: flex;
	;
}

.bux-qa__answer {
	display: flex;
}

.bux-qa__question-marker,
.bux-qa__answer-marker {
	background: $gray-light-80;
	height: $sp-32;
	min-width: $sp-32;
	margin-right: $sp-16;
	font-family: $sans;
	font-size: $ts-20;
	font-weight: 700;
	line-height: 1.675;
	text-align: center;
}

.bux-qa__question-marker {
	line-height: 1.625;
}

.bux-qa__question-text {
	@include heading($ts-20, 26, 700);
	margin: $sp-4 0 0;
}

.bux-qa__answer-text {
	@extend .bux-paragraph;
	margin-bottom: 0;
}

.bux-qa--storytelling {

	.bux-qa__question {
		margin-top: $sp-32;
		margin-bottom: $sp-16;
	}
	
	.bux-qa__question-text {
		font-family: $sans;
		font-size: $ts-base;
		font-weight: 700;
		margin-bottom: 0;
	}
	
	.bux-qa__answer {
		margin-bottom: 0;
	}

	.bux-qa__answer-text {
		font-family: $serif;
		font-size: $ts-base;
	}
	
} 
