.bux-doc-pagination {
	display: flex;
	justify-content: space-between;
}

.bux-doc-pagination__item {
	width: 50%;
}

.bux-doc-pagination__item--prev {
	padding-right: $sp-8;
	text-align: left;
}

.bux-doc-pagination__item--next {
	padding-left: $sp-8;
	text-align: right;
}

.bux-doc-pagination__link {
	display: block;
	border: 2px solid $gray-light-90;
	padding: $sp-24;
	font-weight: 600;
	text-decoration: none;

	&:hover {
		border-color: $gray-dark-60;
		background-color: $gray-dark-60;

		.bux-doc-pagination__label {
			color: $white;
			&:before,
			&:after {
				color: $white;
		}
	}
}

	&:focus {
		outline: 2px solid $focus;
		outline-offset: 1px;
	}
}

.bux-doc-pagination__label {
	font-size: $ts-base;
	line-height: $sp-24;
	color: $scarlet;

	&:before,
	&:after {
		font-family: $icon;
		font-size: $ts-20;
		color: $scarlet;
		vertical-align: rem-calc(-4);
	}
}

.bux-doc-pagination__label--prev {
	&:before {
		content: "\f004";
		margin-right: $sp-8;
	}
}

.bux-doc-pagination__label--next {
	&:after {
		content: "\f005";
		margin-left: $sp-8;
	}
}

.bux-doc-pagination__item--next:first-child {
    margin-left: auto;
}


