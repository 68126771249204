// Global spacing variables.
$sp-4: 4px !default;
$sp-8: 8px !default;
$sp-12: 12px !default;
$sp-16: 16px !default;
$sp-24: 24px !default;
$sp-32: 32px !default;
$sp-48: 48px !default;
$sp-64: 64px !default;
$sp-96: 96px !default;

// Spacing direction values.
$spacing-directions: (null, -top, -right, -bottom, -left);

// Spacing size values
$spacing-sizes: (
	-sp-4: $sp-4,
	-sp-8: $sp-8,
	-sp-12: $sp-12,
	-sp-16: $sp-16,
	-sp-24: $sp-24,
	-sp-32: $sp-32,
	-sp-48: $sp-48,
	-sp-64: $sp-64,
	-sp-96: $sp-96
);

// Based on https://github.com/NHSLeadership/nightingale/blob/develop/utilities/_utilities.spacing.scss#L48
@each $direction in $spacing-directions {
	@each $size, $value in $spacing-sizes {
		// If the direction is "null", add margin to all directions.
		@if $direction == null {
			.bux-mar-all#{$size} {
				margin: $value !important;
			}

			.bux-pad-all#{$size} {
				padding: $value !important;
			}

			.bux-mar-tb#{$size} {
				margin-bottom: $value !important;
				margin-top: $value !important;
			}

			.bux-mar-lr#{$size} {
				margin-left: $value !important;
				margin-right: $value !important;
			}

			.bux-pad-tb#{$size} {
				padding-bottom: $value !important;
				padding-top: $value !important;
			}

			.bux-pad-lr#{$size} {
				padding-left: $value !important;
				padding-right: $value !important;
			}

			// Otherwise create individual direction utilities.
		} @else {
			.bux-mar#{$direction}#{$size} {
				margin#{$direction}: $value !important;
			}

			.bux-pad#{$direction}#{$size} {
				padding#{$direction}: $value !important;
			}
		}
	} // End $spacing-sizes loop
} // End $spacing-directions loop

// This loop creates classes that remove margin and padding from
// an element.
@each $direction in $spacing-directions {
	@if $direction == null {
		.bux-mar-all-none {
			margin: 0 !important;
		}
		.bux-pad-all-none {
			padding: 0 !important;
		}
	} @else {
		.bux-mar#{$direction}-none {
			margin#{$direction}: 0 !important;
		}
		.bux-pad#{$direction}-none {
			padding#{$direction}: 0 !important;
		}
	}
} // End remove loop
