// Definition list.
dl.bux-dl {
	font-size: $ts-base;
	line-height: 1.5;
	margin-top: 0;
}
dt {
	font-family: $sans;
	font-weight: 700;
	margin-bottom: $sp-4;
}
dd {
	font-family: $sans;
	font-weight: 400;
	margin-bottom: $sp-16;
}
