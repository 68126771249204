@mixin button(
	$bg-color: $scarlet,
	$text-color: $white,
	$border-color: $scarlet,
	$bg-hover: $gray-dark-60,
	$text-hover: $white,
	$border-hover: $gray-dark-60
) {
	@include button-reset;
	background-color: $bg-color;
	color: $text-color;
	border: 2px solid $border-color;
	padding: rem-calc(10 20);
	font-family: $sans;
	font-size: $ts-base;
	font-weight: 600;
	text-align: center;
	text-decoration: none;

	&:hover {
		background-color: $bg-hover;
		color: $text-hover;
		border-color: $border-hover;
	}

	&:focus,
	&:active {
		background-color: $bg-color;
		color: $text-color;
		border: 2px solid $border-color;
		outline: 2px solid $focus;
		outline-offset: 1px;
	}
}

// Default buttons.
button:not([class]),
.bux-button {
	@include button();
}
.bux-button--disabled {
	@include button($gray-light-80, $gray-dark-20, $gray-light-80, $gray-light-80, $gray-dark-20, $gray-light-80);
	cursor: not-allowed;
}

// Alt buttons.
.bux-button--alt {
	@include button($white, $scarlet, $scarlet);
}
.bux-button--alt-disabled {
	@include button($gray-light-80, $gray-dark-20, $gray-light-60, $gray-light-80, $gray-dark-20, $gray-light-60);
	cursor: not-allowed;
}

// Small buttons.
.bux-button--small {
	@include button();
	font-size: $ts-sm;
	padding: rem-calc(6 12);
}
.bux-button--small-disabled {
	@include button($gray-light-80, $gray-dark-20, $gray-light-80, $gray-light-80, $gray-dark-20, $gray-light-80);
	cursor: not-allowed;
	font-size: $ts-sm;
	padding: rem-calc(6 12);
}

// Alternate Small buttons.
.bux-button--alt-small {
	@include button($white, $scarlet, $scarlet);
	font-size: $ts-sm;
	padding: rem-calc(6 12);
}

.bux-button--alt-small-disabled {
	@include button($gray-light-80, $gray-dark-20, $gray-light-60, $gray-light-80, $gray-dark-20, $gray-light-60);
	cursor: not-allowed;
	font-size: $ts-sm;
	padding: rem-calc(6 12);
}

// Button with icon.
.bux-button--icon {
	@include button($gray-light-80, $scarlet, $gray-light-80, $gray-dark-60, $white, $gray-dark-60);
	display: flex;
	align-items: center;
}

.bux-button__icon {
	margin-right: $sp-16;
	height: 28px;
	width: 28px;
	display: flex;
	align-items: center;

	.icon {
		padding-top: 9px;
		font-size: 28px;
	}
}

// Use case for links styled as a button
a.bux-button {
    display: inline-block;
}
