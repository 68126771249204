// BUX Multi Form
.bux-form--field-wrapper {
  position: relative;
}

.bux-multi-form {
  position: relative;

  &__title,
  &__form-title,
  &__component {
    margin-bottom: $sp-32;
  }

  &__layout {
    padding-bottom: $sp-48;
  }
}

// Progress Bar
.bux-multi-form__progress {
  background: $gray-light-60;

  &-filled {
    background: $scarlet;
    transition: 400ms all ease-in-out;
  }
}

// Steps
.bux-multi-form__step {
  &-container {
    display: grid;
  }

  &-label {
    margin-top: 2px;
  }

  &-icon {
    background-color: #fff;
    margin-right: $sp-8;
    font: inherit;
    color: $gray-dark-80;
    width: 20px;
    height: 20px;
    border: 2px solid $gray-dark-80;
    border-radius: 50%;
    transform: translateY(2.5px);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      font-family: "bux-icons";
      transform: scale(0);
    }

    &--current {
      border-color: $scarlet;

      &::before {
        transform: scale(1);
        transition: 120ms transform ease-in-out;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $scarlet;
      }
    }

    &--complete {
      &::before {
        content: "\f101";
        font-size: 11px;
        transform: scale(1);
      }
    }
  }
}

// Forms
.bux-multi-form__form {
  display: none;

  &:first-child {
    display: block;
  }
}

// Buttons
.bux-multi-form__button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: $sp-16;
}

// Modifiers
.bux-multi-form__layout--horizontal {
  .bux-multi-form__progress {
    height: $sp-4;

    &-filled {
      width: 0;
    }
  }

  .bux-multi-form__step-container {
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    padding: 0 $sp-16 0 $sp-4;
    margin-bottom: $sp-48;
  }

  .bux-multi-form__step {
    display: grid;
    grid-template-columns: 18px auto;
    gap: 8px;
    margin-top: $sp-8;
  }
}

.bux-multi-form__layout--vertical {
  display: flex;

  .bux-multi-form__progress {
    width: $sp-4;
    align-self: stretch;

    &-filled {
      width: 100%;
      height: 0;
    }
  }

  .bux-multi-form__step-container {
    grid-template-rows: repeat(auto-fit, minmax(25%, 1fr));
    padding: $sp-8 $sp-32 0 $sp-4;
  }

  .bux-multi-form__step {
    display: flex;
  }

  .bux-multi-form__form-container {
    flex-grow: 1;
    padding-top: $sp-8;
  }
}
