.bux-card {
  border-top: 4px solid $scarlet;
  color: $gray-dark-80;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.bux-card--gray {
  background-color: $gray-light-90;
}

.bux-card__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

bux-card .bux-image {
  object-fit: cover;
  aspect-ratio: 16/9;
}

.bux-card__content {
  padding: $sp-24;
  border: 2px solid $gray-light-90;
  border-top: none;
  position: relative;
  z-index: 2;
  pointer-events: none;
  flex: 1;
}

.bux-card--no-border,
.bux-card--storytelling,
.bux-card--horizontal,
.bux-card--horizontal-storytelling {
  .bux-card__content {
    border: 0;
    padding: 0;
    padding-top: $sp-24;
    padding-right: $sp-24;
  }
}

.bux-card--storytelling,
.bux-card--horizontal-storytelling {
  border-top: none;
  .bux-card__heading {
    font-family: $serif;
    font-size: $ts-md;
    font-weight: 700;
  }
}

.bux-card__read-time {
  font-size: $ts-base;
  color: $gray-dark-40;
  margin-top: $sp-8;

  &::before {
    content: "\f302";
    font-family: $icon;
    font-size: $ts-sm;
    margin-right: 6px;
    margin-top: 1.5px;
    float: left;
  }
}

*.bux-card__heading {
  font-size: $ts-22;
  line-height: rem-calc(28);
  font-weight: 700;
  margin-bottom: $sp-8;
}

.bux-card__body {
  font-size: $ts-18;
  line-height: rem-calc(28);
  font-weight: 400;
  margin-top: $sp-8;
  margin-bottom: $sp-16;
}

.bux-card__cta {
  margin-top: $sp-8;
  position: relative;
  z-index: 2;

  a {
    color: $gray-dark-80;
    pointer-events: all;
    text-decoration: none;
    display: table;

    &::after {
      content: "\f005";
      font-size: $ts-sm;
      font-family: $icon;
      font-weight: 600;
      color: $scarlet;
      padding-left: $sp-8;
      transition-duration: 0.05s;
      display: table-cell;
      vertical-align: middle;
    }

    &:hover {
      color: $scarlet;
      &::after {
        padding-left: $sp-16;
        transition-duration: 0.05s;
      }
    }

    &:focus {
      outline: 2px solid $focus;
      outline-offset: $sp-4;
    }
  }
}

.bux-card--linked-headline,
.bux-card--storytelling,
.bux-card--horizontal-storytelling {
  .bux-card__body {
    margin-bottom: $sp-8;
  }
}

.bux-card--linked-headline {
  .bux-card__heading {
    display: inline-block;
    margin-bottom: 0;
    padding-right: $sp-24;
  }
}

.bux-card__heading--link {
  text-decoration: none;
  color: $gray-dark-80;
  pointer-events: all;

  &::after {
    content: "\f005";
    font-size: $ts-md;
    font-family: $icon;
    color: $scarlet;
    transition-duration: 0.05s;
    float: right;
    padding-right: $sp-24;
    padding-top: $sp-24;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:focus {
    outline: 2px solid $focus;
  }
}

.bux-card__heading--storytelling-link {
  text-decoration: none;
  color: $gray-dark-80;
  pointer-events: all;

  &:focus {
    outline: 2px solid $focus;
  }
}

.bux-card:hover {
  .bux-card__heading--link {
    color: $scarlet;
    text-decoration: none;
    &::after {
      transition-duration: 0.05s;
      padding-right: $sp-16;
    }
  }
  .bux-card__heading--storytelling-link {
    text-decoration: none;
  }
}

.bux-card--horizontal-storytelling:hover .bux-card__heading span {
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  color: $gray-dark-80;
}

.bux-card--storytelling:hover .bux-card__heading span,
.bux-card--horizontal-storytelling:hover .bux-card__heading span {
  color: $gray-dark-80;
  text-decoration: none;
  box-shadow: inset 0 -3px 0 0 $gray-dark-80;
  text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff;
}

.bux-card--storytelling .bux-card__heading span:hover,
.bux-card--horizontal-storytelling .bux-card__heading span:hover {
    color: $gray-dark-80;
}
.bux-card__taxonomy {
  color: $scarlet;
  text-transform: uppercase;
  font-size: $ts-sm;
  line-height: rem-calc(18);
  margin-bottom: $sp-8;
  display: inline-block;
}

.bux-card--featured {
  display: block;
  background-color: $white;
  max-width: 100%;
  margin-right: 0;

  .bux-card__content {
    padding: $sp-32;
  }

  .bux-button {
    text-decoration: none;
    display: inline-block;
  }

  &:hover .bux-button {
    background-color: $gray-dark-60;
    color: $white;
    border-color: $gray-dark-60;
  }
}

@include breakpoint(md) {
  .bux-card--horizontal,
  .bux-card--horizontal-storytelling {
    border-top: none;
    flex-direction: row;
    max-width: 100%;

    .bux-card__heading {
      font-size: $ts-md;
    }

    .bux-image {
      max-width: 33%;
      margin-right: $sp-32;
      object-fit: cover;
    }

    .bux-card__content {
      padding-top: 0;
    }
  }

  .bux-card--horizontal-storytelling {
    border-bottom: 2px solid $gray-light-60;
    padding-bottom: $sp-32;

    .bux-card__content {
      padding-left: 0;
      border: 0;
    }

    .bux-card__body {
      margin-bottom: $sp-8;
    }
  }
}
