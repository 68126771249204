.bux-tabpanel {
  &__tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    outline: 0;
    overflow: auto hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    width: auto;
    will-change: scroll-position;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $bp-md) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__tabs--scrollable {
    display: flex;
    height: auto;
    width: 100%;
  }

  &__tab {
    @include button-reset();
    background-color: $white;
    border: 2px solid $white;
    color: $gray-dark-80;
    cursor: pointer;
    padding: 12px $sp-16;
    margin-right: 1px;
    font-family: $sans;
    font-size: $ts-base;
    font-weight: 400;
    line-height: 1.25;
    position: relative;

    @media (max-width: $bp-md) {
      width: 100%;
      text-align: left;
    }

    &::after {
      background: $gray-light-80;
      bottom: 0;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &[aria-selected="true"] {
      border: 2px solid $gray-light-80;
      outline: none;

      @media (max-width: $bp-md) {
        border: 2px solid transparent;
      }

      &::after {
        background: $scarlet;
      }
    }
    &:focus {
      border: 2px solid $gray-light-80;
      outline: 2px solid $focus;
      outline-offset: -2px;

      &::after {
        background: $scarlet;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $gray-light-90;
        border-color: $gray-light-90;
        color: $gray-dark-80;

        &::after {
          background: $gray-dark-60;
        }
      }
    }
  }

  &__panel {
    padding: $sp-32;
    border-bottom: 2px solid $gray-light-80;
    font-family: $serif;
    font-size: $ts-base;
    font-weight: 400;
    margin-top: -4px;

    &:focus {
      outline: 2px solid $focus;
      outline-offset: -2px;
      border-color: $gray-light-40;
    }
  }
}
