// Fonts
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;600");
@import url("https://assets.bux.osu.edu/bux-icons/bux-icons.css");
@import url("https://s3.amazonaws.com/assets.bux.osu.edu/bux-webfonts2/bux-webfonts2.css");

// Settings
@import "settings";

// Global
@import "utils/reset";
@import "core/spacing";
@import "core/breakpoints";
@import "core/grid";
@import "core/colors";
@import "core/typography";
@import "core/mixins";
@import "core/base";

// Components
/* inject: /dist/components-list.txt */
@import "/builds/osu/bux/bux-source/src/components/accordion-group/_accordion-group.scss";
@import "/builds/osu/bux/bux-source/src/components/alert/_alert.scss";
@import "/builds/osu/bux/bux-source/src/components/back-to-top/_back-to-top.scss";
@import "/builds/osu/bux/bux-source/src/components/breadcrumb/_breadcrumb.scss";
@import "/builds/osu/bux/bux-source/src/components/buckeye-alert/_buckeye-alert.scss";
@import "/builds/osu/bux/bux-source/src/components/button/_button.scss";
@import "/builds/osu/bux/bux-source/src/components/card-collection-carousel/_card-collection-carousel.scss";
@import "/builds/osu/bux/bux-source/src/components/card/_card.scss";
@import "/builds/osu/bux/bux-source/src/components/colors/_colors.scss";
@import "/builds/osu/bux/bux-source/src/components/CTA-collection/_CTA-collection.scss";
@import "/builds/osu/bux/bux-source/src/components/doc-pagination/_doc-pagination.scss";
@import "/builds/osu/bux/bux-source/src/components/factoid/_factoid.scss";
@import "/builds/osu/bux/bux-source/src/components/featured-card/_featured-card.scss";
@import "/builds/osu/bux/bux-source/src/components/footer/_footer.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/checkbox/_checkbox.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/multi-step-form/_multi-step-form.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/radio-button/_radio-button.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/selection-dropdown/_selection_dropdown.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/switch/_switch.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/text-area/_text-area.scss";
@import "/builds/osu/bux/bux-source/src/components/forms/text-field/_text-field.scss";
@import "/builds/osu/bux/bux-source/src/components/header/_header.scss";
@import "/builds/osu/bux/bux-source/src/components/heading/_heading.scss";
@import "/builds/osu/bux/bux-source/src/components/hero/_hero.scss";
@import "/builds/osu/bux/bux-source/src/components/horizontal-rule/_horizontal-rule.scss";
@import "/builds/osu/bux/bux-source/src/components/image/_image.scss";
@import "/builds/osu/bux/bux-source/src/components/link-list/_link-list.scss";
@import "/builds/osu/bux/bux-source/src/components/link/_link.scss";
@import "/builds/osu/bux/bux-source/src/components/list-description/_list-description.scss";
@import "/builds/osu/bux/bux-source/src/components/list-ordered/_list-ordered.scss";
@import "/builds/osu/bux/bux-source/src/components/list-unordered/_list-unordered.scss";
@import "/builds/osu/bux/bux-source/src/components/listicle/_listicle.scss";
@import "/builds/osu/bux/bux-source/src/components/menu/_menu.scss";
@import "/builds/osu/bux/bux-source/src/components/osu-navbar/_osu-navbar.scss";
@import "/builds/osu/bux/bux-source/src/components/pagination/_pagination.scss";
@import "/builds/osu/bux/bux-source/src/components/panel/_panel.scss";
@import "/builds/osu/bux/bux-source/src/components/paragraph/_paragraph.scss";
@import "/builds/osu/bux/bux-source/src/components/q+a/_q+a.scss";
@import "/builds/osu/bux/bux-source/src/components/quote/_quote.scss";
@import "/builds/osu/bux/bux-source/src/components/search-form/_search-form.scss";
@import "/builds/osu/bux/bux-source/src/components/sidebar-nav/_sidebar-nav.scss";
@import "/builds/osu/bux/bux-source/src/components/skip-navigation/_skip-navigation.scss";
@import "/builds/osu/bux/bux-source/src/components/social-links/_social-links.scss";
@import "/builds/osu/bux/bux-source/src/components/table/_table.scss";
@import "/builds/osu/bux/bux-source/src/components/tabpanel/_tabpanel.scss";
@import "/builds/osu/bux/bux-source/src/components/text-link/_text-link.scss";
@import "/builds/osu/bux/bux-source/src/components/tile/_tile.scss";
@import "/builds/osu/bux/bux-source/src/components/timeline/_timeline.scss";
